import React, {useState, useEffect} from 'react'
import { urlFor, client } from '../../../../../client'
import {Link} from 'react-router-dom'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import apple from '../../../../../assets/use/apple.webp';
import grapes from '../../../../../assets/use/grapes.jpg';
import tomatoes from '../../../../../assets/use/tomatoes.jpg';
import oreanges from '../../../../../assets/use/oreanges.jpg';
import gardening from '../../../../../assets/use/gardening.webp';

import './EndTrees.scss'


const EndTrees = () => {

  const Tdata = [
    {
      cover: apple,
      para: "Apple",
      desc: "Red Listed",
    },
    {
      cover: grapes,
      para: "Melia",
      desc: "Dying Species",

    },
    {
      cover: tomatoes,
      para: "Melia",
      desc: "Thriving still",

    },
    {
      cover: oreanges,
      para: "Melia",
      desc: "oranges",

    },
    {
      cover: gardening,
      para: "Gardening",
      desc: "Red listed",

    },
  ]

  
const [theendangeredspecies, setTheendangeredspecies] = useState([]);

useEffect(() => {
  const query = '*[_type == "theendangeredspecies"]';

  client.fetch(query).then((data) =>{
    setTheendangeredspecies(data);
  });
 
}, [])
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
  }

  return (
    <>
      <div className="app__endtrees-head">
        <h1>The Endangered Trees</h1>
      </div>

      <div className='app__endtrees'>
      <Slider {...settings}>
        {theendangeredspecies.map((value, index) => {
          return (
            <>
              <div key={index}>
                <div className='app__nametop d_flex'>
                  <span className='tright'>{value.description}</span>
                </div>
                <div className='img'>
                  <a href={value.cover}><img src={urlFor(value.imgUrl)} alt={value.para} /></a>
                </div>
              </div>
            </>
          )
        })}
      </Slider>
      </div>
    </>

  )
}

export default EndTrees