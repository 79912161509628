import React, { useState, useEffect } from 'react';
import { MotionWrap } from '../../wrapper';
import { client, urlFor } from '../../client';
import { Link } from 'react-router-dom';


import './NewHistory.scss'
const NewHistory = () => {
    const [history, setHistory] = useState([]);

    useEffect(() => {
      const query = '*[_type == "history"]';
      client.fetch(query).then((data) =>{
        setHistory(data);
      });
     
    }, [])
  return (
    <>
        <div className="app__newhist-head" id="history">
            <h1>Our Story</h1>
            <div className='hwadme'/>
        </div>

        {history.map((item, index) =>(

            <div className="app__newhistory" key={index}>
                <div className='img'>
                    <p>{item.mission}</p>
                    <h1>- {item.title}</h1>
                    <img src={urlFor(item.imgUrl)} alt={item.title} />
                </div>
                <div className='cont'>
                    <p>{item.description}</p>
                    <Link to="/ourhistory/projecthistory/history-time-line"><h2>Learn about our project history</h2></Link>
                </div>
            </div>
    ))}
    </>
  )
}

// export default NewHistory

export default MotionWrap(NewHistory, 'app__testimonial',
  'history',
);
