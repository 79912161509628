import React, {useState} from 'react'
import './MyForestry-gallery.scss'

import {BiChevronRightCircle, BiChevronLeftCircle} from 'react-icons/bi'

import {MdOutlineClose} from 'react-icons/md'


const MyForestry = ({galleryImages}) => {

    
  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = (index) => {
    setSlideNumber(index)
    setOpenModal(true)
  }

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0 
    ? setSlideNumber( galleryImages.length -1 ) 
    : setSlideNumber( slideNumber - 1 )
  }

  // Next Image  
  const nextSlide = () => {
    slideNumber + 1 === galleryImages.length 
    ? setSlideNumber(0) 
    : setSlideNumber(slideNumber + 1)
  }
  return (
    <>
          <div>

{openModal && 
  <div className='sliderWrap'>
    <MdOutlineClose className='btnClose' onClick={handleCloseModal}  />
    <BiChevronLeftCircle  className='btnPrev' onClick={prevSlide} />
    <BiChevronRightCircle className='btnNext' onClick={nextSlide} />
    <div className='fullScreenImage'>
      <img src={galleryImages[slideNumber].img} alt='' />
    </div>
  </div>
}

{/* <br />
Current slide number:  {slideNumber}
<br />
Total Slides: {galleryImages.length}
<br /><br /> */}

<div className='galleryWrap'>
  {
    galleryImages && galleryImages.map((slide, index) => {
      return(
        <div 
          className='single' 
          key={index}
          onClick={ () => handleOpenModal(index) }
        >
          <img src={slide.img} alt='' />
        </div>
      )
    })
  }
</div>

</div>
    </>
  )
}

export default MyForestry