import React from 'react'


import { Navbar } from '../../components';
import MySlide from './Slick/MySlide';
import Activity from './Activity/Activity';
import HeaderCamp from './Header/HeaderCamp';
import Exploration from './Exploration/Exploration';
import AboutCamp from './AboutCamp/AboutCamp';
import Letter from './Letter/Letter';
import { Footer } from '../../container';

import './Camp.scss';

const Camp = () => {

  return (
    <>
      <Navbar/>
      <HeaderCamp/>
      <AboutCamp/>
      {/* <MySlide/> */}
      <Activity/>
      <Letter/>
      {/* <Footer/> */}
    </>
  )
}

export default Camp