import React, { useState, useEffect } from 'react';
import { client, urlFor } from '../../../../client';


import Navbar from '../../../../components/Navbar/Navbar'
import {Footer} from '../../../../container'
import eco from '../../../../assets/eco.jpg'

import RegenProjects from './RegenProjects/RegenProjects'

import Slider from './RegenSlider/TopCart'
import './Regenerative.scss'

const Regenerative = () => {
    const [regenexpertice, setRegenexpertice] = useState([]);

    useEffect(() => {
      const query = '*[_type == "regenexpertice"]';
      client.fetch(query).then((data) =>{
        setRegenexpertice(data);
      });
     
    }, [])
  return (
    <>
        <Navbar/>

        <Slider/>

        <div className="app__regenerative-container">
            <div className="regenerative-heading">
                <h1>Regenerative Agriculture</h1>
            </div>
            <div className="regenerative-p">
                <p>Regenerative Agriculture is a series of <strong>holistic practices</strong> designed to create 
                    <strong> net beneficial impacts on ecosystem services</strong>. This includes improving net <strong>carbon sequestration</strong> ,
                     improving <strong>Watershed</strong> and  <strong>Soil health</strong> and promoting  habitat rich in  <strong>Biodiversity!</strong> By necessity, 
                     these activities must provide net beneficial economic and social impacts
                     for Farmers and local communities if they are to be sustainable over the long term.
                </p>
                <p>Our approach to Regenerative Agriculture looks to the core components
                     of all frameworks to define the <strong>common and necessary traits required to achieve the industry’s common goals </strong>
                       Our activities can be applied to a <strong> variety of potential standards or company specific goals </strong>
                        ;while also allowing for the flexibility to be applied across all
                    <strong> agricultural supply chains </strong> no matter the cultural, geographic or biophysical nature of the activities.
                  </p>
            </div>
            <div className='regenerative-img'>
              <img src={eco} alt="eco" />
            </div>

            <div className='regenrative-exp'>
              <div className="head">
                <h1>Our Expertise</h1>
                <div className="app__linre"/>
              </div>
              <div className="regenerative-loop">
                {regenexpertice.map((item, index) =>{
                  return(
                    <div className="loop" key={index}>
                        <img src={urlFor(item.imgUrl)} alt="" />
                        <div className='loop-content'>
                          <h1><strong>{item.title}</strong> </h1>
                          <p>{item.description}</p>
                        </div>
                    </div>
                  )
                })}
              </div>
              
            </div>

              <div className="app__regen-porj-con">
                <h1 className='regenrative-pro-header'>Regenerative Projects</h1>
                <div className="app__liner-proj"/>
              </div>

              <RegenProjects/>

        </div>
        
        <Footer/>
    </>
  )
}

export default Regenerative