import React from 'react';
import { BsInstagram, BsTwitter, BsPinterest, BsLinkedin} from 'react-icons/bs';
import { FaFacebookF} from 'react-icons/fa';
import twitter from '../assets/twitter.png';
import link from '../assets/in.png';
import fb from '../assets/fb.png';
import insta from '../assets/insta.png';
import {motion } from 'framer-motion';


const SocialMedia = () => {
  return (
    <>
    
    {/* <div 
    className="app__social"  >
        <div>
        <a rel="noreferrer" href="https://www.instagram.com/myadventurers/"  target= "_blank">
            <motion.img src={insta} alt="" 
                whileInView= {{opacity: 1}}
                whileHover = {{scale: 1.2}}
                transition= {{duration: .5, type :"tween"}}
            />

            </a>
        </div>
        <div>
        <a rel="noreferrer" href="https://www.facebook.com/lewis.vardy.5/"  target= "_blank">
            <motion.img src={fb} alt="" 
                whileInView= {{opacity: 1}}
                whileHover = {{scale: 1.2}}
                transition= {{duration: .5, type :"tween"}}
            />

            </a>
        </div>
        <div>
            <a rel="noreferrer" href="https://www.linkedin.com/in/bob-van-der-bijl-4a01525/"  target= "_blank">
            <motion.img src={link} alt="" 
                whileInView= {{opacity: 1}}
                whileHover = {{scale: 1.2}}
                transition= {{duration: .5, type :"tween"}}
            />

            </a>
        </div>
        <div>
        <a rel="noreferrer" href="https://twitter.com/treasure_trees"  target= "_blank">
            <motion.img src={twitter} alt="" 
                whileInView= {{opacity: 1}}
                whileHover = {{scale: 1.2}}
                transition= {{duration: .5, type :"tween"}}
            />

            </a>
        </div>
    </div> */}
    </>
  )
}

export default SocialMedia