import React from 'react';
import Navbar from '../../components/Navbar/Navbar';

import './Gallery.scss'

import PhotoGallery from './PhotoGallary/PhotoGallery'
import {Footer,Letter} from '../../container'

let Gallery = () => {

  return (
    <>
      <Navbar/>
      <div className="app__gallery-divider"/>
      <PhotoGallery/>
      {/* <Letter/> */}
      <Footer/>
  </>
  )
}

export default Gallery;
