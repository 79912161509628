import React, {useState, useEffect} from "react"
import { urlFor, client } from '../../client'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import pineapple from '../../assets/pine.jpeg'
import melon from '../../assets/melon.png'
import pawpaw from '../../assets/paw.jpg'
import mango from '../../assets/mango.jpg'

import './TopCart.scss'


const TopCart = () => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    fade: true,
  }

  const [treeandcropsslider, setTreeandcropsslider] = useState([]);

  useEffect(() => {

    const query = '*[_type == "treeandcropsslider"]';

    client.fetch(query).then((data) =>{
      setTreeandcropsslider(data);
    });
   
  }, []);

  return (
    <>
      <Slider {...settings}>
        {treeandcropsslider.map((value, index) => {
          return (
            <>
              <div className='app__slickslider-tress' key={index}>
                <div className='img'>
                    <img 
                    src={urlFor(value.imgUrl)} 
                    alt='' 
                    />
                </div>
              </div>
            </>
          )
        })}
      </Slider>
    </>
  )
}

export default TopCart
