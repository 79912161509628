import React, { useState, useEffect } from 'react';
import { client, urlFor } from '../../client';

import { MotionWrap } from '../../wrapper';

import './Economic.scss'


const Economic = () => {
  
    const [environmentgallery, setEnvironmentgallery] = useState([]);

    useEffect(() => {
      const query = '*[_type == "environmentgallery"]';
  
      client.fetch(query).then((data) =>{
        setEnvironmentgallery(data);
      });
     
    }, [])

    const [environmentdata, setEnvironmentdata] = useState([]);

    useEffect(() => {
      const query = '*[_type == "environmentdata"]';
  
      client.fetch(query).then((data) =>{
        setEnvironmentdata(data);
      });
     
    }, [])

  return (
    <>
        <div className="app__economic-content">

            <div className="content-img">
                {environmentgallery.map((item, index) =>(
                    <div className='one' key={index}>
                        <img src={urlFor(item.imgUrl)} alt={item.landState} />
                        <p>{item.landState}</p>
                    </div>
                ))}
            </div>


            {environmentdata.map((item, index) =>(
            <div className="content-p-ecom" key={index}>
                <h1>{item.title}</h1>
                <p>{item.description}</p>
                </div>
            ))}
        </div>
    </>
  )
}


export default MotionWrap(Economic, 'app__testimonial',
  'economic',
);
