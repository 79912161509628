import React from 'react';
import { motion } from 'framer-motion';
import {Navbar,Socials} from '../../components';
import Map from './Map/Map';
import { BsArrowRight} from 'react-icons/bs';
import {Footer} from '../../container';
import emailjs from 'emailjs-com';

import twitter from '../../assets/twitter.svg';
import insta from '../../assets/instagram.svg';
import youtube from '../../assets/youtube.svg';
import linkedin from '../../assets/linkedin.svg';
import facebook from '../../assets/facebook.svg';


import './Contact.scss';

const Contact = () => {

  
  function sendEmail(e){
    e.preventDefault();
    emailjs.sendForm('service_482g0x8', 'template_y6bn3zw', e.target, 'B7wzn3i2lHrhYXdnV').then(res=>{
      console.log(res)
    }).catch(err=> console.error(err))
  }

  const img = [

    {
     insta,
     isntalinks: "https://www.instagram.com/treasure.trees/",
     fblink: "https://www.facebook.com/treasuretreeskenya/",
     facebook,
     linkedinlink: "https://www.linkedin.com/in/treasure-trees-307027246/",
     linkedin,
     twitterlink: "https://twitter.com/treasure_trees",
     twitter,
     youtubelink: "https://www.youtube.com/channel/UCXnvff5IqnGeFv0Cht-RwJg/featured",
     youtube,
    }
 ]
  return (
    <>
    <Navbar/>
    <div className="app__contact-finaldraft">

      <h1>Let’s Connect!</h1>
      <div className='cont-line-me'/>

      <p>How can we help? We look forward to speaking with you. 
        Send us a message, and we will be in touch within a business day.</p>

     
    </div>
    <div className="app__contact-form-bg">




      <form className='app__contanct-me-form' onSubmit={sendEmail}>
        <div className='label-input'>
          {/* <label htmlFor="">Name</label> */}
          <input className="input-one" type="text" name='name' placeholder='Name*' required/>
          <input className="input-two" type="email" name='useremail' placeholder='E-mail*' required/>
        </div>
        <div className='label-textarea'>
          {/* <label >How can we help?</label> */}
          <textarea name='message'  placeholder="Send us a message?"/>
        </div>
        <div className='app__btn-form'>
          <button type='submit'>Send <BsArrowRight/></button>
        </div>
      </form>





      <div className='other-ways-connect'>
        <h1>More ways to connect</h1>
        <div className='app__ways-line'/>
      <div className='media-me'>
        <div className="app__contact-s">
            <li>Email</li>
            <a href="mailto:treasuretreeskenya@gmail.com">treasuretreeskenya@gmail.com</a>
        </div>
        <div className="app__contact-s" >
          <li href="">Phone</li>
          <a href="tel:+254725707814">(254)725707814</a>
        </div>
        <div className="app__contact-s" >
          <li>Socials</li>
          <div className='social-imgagesc'>
                {img.map((item, index) =>(
                    <div key={index} className="one-two-three">
                        <a href={item.fblink} target="_blank" rel="noopener noreferrer">
                            <motion.img 
                            whileInView={{opacity: 1}}
                            whileHover= {{scale: 1.5}}
                            transition = {{duration: .5, type: "tween"}}
                            src={item.facebook} 
                            alt='facebook' 
                            />
                        </a>
                        <a href={item.isntalinks} target="_blank" rel="noopener noreferrer">
                            <motion.img 
                            whileInView={{opacity: 1}}
                            whileHover= {{scale: 1.5}}
                            transition = {{duration: .5, type: "tween"}}
                            src={item.insta} 
                            alt='twitter' 
                            />
                        </a>
                        <a href={item.twitterlink} target="_blank" rel="noopener noreferrer">
                            <motion.img 
                            whileInView={{opacity: 1}}
                            whileHover= {{scale: 1.5}}
                            transition = {{duration: .5, type: "tween"}}
                            src={item.twitter} 
                            alt='twitter' 
                            />
                        </a>
                        <a href={item.linkedinlink} target="_blank" rel="noopener noreferrer">
                            <motion.img 
                            whileInView={{opacity: 1}}
                            whileHover= {{scale: 1.5}}
                            transition = {{duration: .5, type: "tween"}}
                            src={item.linkedin} 
                            alt='twitter' 
                            />
                        </a>
                        <a href={item.youtubelink} target="_blank" rel="noopener noreferrer">
                            <motion.img 
                            whileInView={{opacity: 1}}
                            whileHover= {{scale: 1.5}}
                            transition = {{duration: .5, type: "tween"}}
                            src={item.youtube} 
                            alt='youtube' 
                            />
                        </a>
                    
                    </div>
                ))}

                {/* <img src={twitter} alt="" /> */}
            </div>
        </div>
      </div>
      </div>
      <Map id="map"/>
    <Footer/>
    </div>
   </>
  )
}

export default Contact;