import React, {useState} from 'react'
import { client } from '../../../client'

import { MdMail } from 'react-icons/md'
import { BsArrowRight, BsFillPersonFill} from 'react-icons/bs'
import {Link} from 'react-router-dom'

import './Letter.scss'
import { images } from '../../../constants'

const Letter = () => {
    const [formData, setFormData] = useState({ name: '', email: '' });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const { name, email}  = formData;
        
    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () =>{
        setLoading(true);

        const newsletter = {
            _type: 'newsletter',
            name : formData.name,
            email: formData.email,
        };

        client.create(newsletter)
        .then(() => {
          setLoading(false);
          setIsFormSubmitted(true);
        })
        .catch((err) => console.log(err));
    };
  

  return (
    <>
        <div 
        className="app__news"  >

            <div className="cont">
                <div className="connt">
                        <h1>The mailing list</h1>
                        <p>You wanna join us? We got you. Get the best tips and how-to´s weekly on your inbox.
                        </p>
                    </div>

                    {!isFormSubmitted ?(

                        <form className='app__ews-letter' onSubmit={e => {e.preventDefault();}} >
                            <div className='labelme'>
                                <div className="labels-one">
                                    <input type="text" className="on" placeholder='Your Full Name ...' required  name="name" value={name} onChange={handleChangeInput}/>
                                </div>
                                <div className="labels-two">
                                    <input type="email" placeholder='Email Address ...' required  name="email" value={email} onChange={handleChangeInput}/>
                                </div>
                            </div>
                            <div className='nbtn'>
                                <button type="button" onClick={handleSubmit}>{!loading ? "Subscribe" : "Subscribing ..."}</button>
                            </div>
                    </form>
                    ):(
                        <div className='success-m'>
                            <p>Succesfull</p>
                        </div>
                    )}


                    <div className="take-btn">
                        <p>For more info about our work reach us through our page and send us a message. You'll be in touch with our Founder BOB !</p>
                        <div className="container-btn">
                            <Link to="/contact"><button>Reach us</button></Link>
                        </div>
                    </div>
            </div>
        </div>
    </>
  )
}

export default Letter