import React, {useState, useEffect} from 'react'
import { urlFor, client } from '../../../client'

import { Navbar } from '../../../components'
import { Footer } from '../../../container'

import Slider from './Header/Comm'


import './Community.scss'

const Community = () => {
  const [community, setCommunity] = useState([]);

  useEffect(() => {

    const query = '*[_type == "community"]';

    client.fetch(query).then((data) =>{
      setCommunity(data);
    });
   
  }, []);
  
  return (
    <>
    
      <Navbar/>

      <Slider/>

      <div className='app__content-comm'>
        <h1>We offer meaningful programs for women and their families in order to enrich lives, and we serve the entire community by empowering others. With EmBe, women excel. Youth thrive. Families succeed.</h1>
      </div>


      <div className="app__comuunity-outreachprog">

        <div className='app__communit-programs'>
          {community.map((item, index) =>(
            <div className="contain-me" key={index}>
              <div className="app__coom-imgm">
                <img src={urlFor(item.imgUrl)} alt="" />
                <div className="app__conte-co">
                  <h1>{item.title}</h1>
                  <p>{item.desc}</p>
                  <div className="app__btbt">
                      <button>{item.urlLink}</button>
                  </div>
                </div>
              </div>
              <div className="app__coom-img">
                <img src={urlFor(item.imgUrl2)} alt="" />
                <div className="app__conte-co">
                  <h1>{item.title2}</h1>
                  <p>{item.desc2}</p>
                  <div className="app__btbt">
                      <button>{item.urlLink}</button>
                  </div>
                </div>
              </div>
             
            </div>

          ))}
        </div>
      </div>

      <div className='ap__commm-links'>
        <div className='one'>
          <h1>Volunteers</h1>
          <h2>Learn More</h2>
        </div>
      
        <div className='two'>
          <h1>donate</h1>
          <h2>Support us</h2>
        </div>
      
        <div className='three'>
          <h1>careers</h1>
          <h2>apply now</h2>
        </div>
      
      </div>

      <div className="app__comuunity-outreachprog">

        <div className='app__communit-programs'>
          {community.map((item, index) =>(
            <div className="contain-me" key={index}>
              
              <div className="app__coom-imgm">
                <img src={urlFor(item.imgUrl3)} alt="" />
                <div className="app__conte-co">
                  <h1>{item.title3}</h1>
                  <p>{item.desc3}</p>
                  <div className="app__btbt">
                      <button>{item.urlLink}</button>
                  </div>
                </div>
              </div>
              <div className="app__coom-img">
                <img src={urlFor(item.imgUrl4)} alt="" />
                <div className="app__conte-co">
                  <h1>{item.title4}</h1>
                  <p>{item.desc4}</p>
                  <div className="app__btbt">
                      <button>{item.urlLink}</button>
                  </div>
                </div>
              </div>
            
            </div>

          ))}
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Community