import React, { useState, useEffect } from 'react';
import { client, urlFor } from '../../client';
import Navbar from '../../components/Navbar/Navbar'
import { Link } from 'react-router-dom'
import { Footer } from '../../container'

import twitter from '../../assets/twitter.svg'
import instagram from '../../assets/instagram.svg'
import linkedin from '../../assets/linkedin.svg'
import facebook from '../../assets/facebook.svg'
import melon from '../../assets/melon.png'

import './Donate.scss'

const Donate = () => {

  const social = [
    {
      imgUrl: instagram,
      linkUrl: "https://www.instagram.com/treasure.trees/"
     },
    {
      imgUrl: twitter,
      linkUrl: "https://twitter.com/treasure_trees"
     },
    {
      imgUrl: linkedin,
      linkUrl: "https://www.linkedin.com/in/treasure-trees-307027246/"
     },
    {
      imgUrl: facebook,
      linkUrl: "https://web.facebook.com/profile.php?id=100083281873416"
     },
  ]


  // const donatequestions = [
  //   {
  //     queation1: "How is my donation used?",
  //     answer1: "Because of supporters, St. Jude can share the breakthroughs it makes. As a result, we inspire more collaboration between doctors and researchers worldwide and provide more lifesaving treatments for children everywhere.",
  //     question2: "What is Giving Tuesday and when is it?",
  //     answer2:"The next Giving Tuesday is on Nov. 29, 2022. Giving Tuesday is a national campaign occurring on the Tuesday following Thanksgiving, Black Friday and Cyber Monday. It was established as a way to begin the giving season and encourage people to contribute charitable donations to nonprofits",
  //     question3: "What are some Giving Tuesday participation ideas?",
  //     answer1question3: "Encourage others: After you give, fundraise or volunteer, encourage others to get involved by sharing your participation using the popular hashtag #GivingTuesday.",
  //     answer2question3:"Fundraise: When you ask friends and family to help raise money for a charity, you increase the amount you would've given on your own! There are many ways you can fundraise for St. Jude.",
  //     answer3question3:"Volunteer: Volunteer your time with a charity. Volunteers are extremely valuable for helping to complete work. Some nonprofits offer virtual volunteer opportunities, too.",
  //     question4:"How can I donate to St. Jude on Giving Tuesday?",
  //     answer1question4:"Online: Donate on our online donation form.",
  //     answer2question4:"Phone: Donate by calling 1-800-822-6344 Monday – Friday, between 7 a.m. – 9 p.m. CT.",
  //     question5:"What is a good Giving Tuesday quote?",
  //     answer5:"I'd rather have a million people give me a dollar than one give me a million. That way you've got a million people involved.s",
  //   }
  // ]


  const [donateheader, setDonateheader] = useState([]);

  useEffect(() => {
    const query = '*[_type == "donateheader"]';
    client.fetch(query).then((data) =>{
      setDonateheader(data);
    });
   
  }, [])
  const [donationcards, setDonatecards] = useState([]);

  useEffect(() => {
    const query = '*[_type == "donationcards"]';
    client.fetch(query).then((data) =>{
      setDonatecards(data);
    });
   
  }, [])
  const [donatequestions, setDonatequestions] = useState([]);

  useEffect(() => {
    const query = '*[_type == "donatequestions"]';
    client.fetch(query).then((data) =>{
      setDonatequestions(data);
    });
   
  }, [])
  return (
    <>
      <Navbar/>

      <div className="dont-bb-now" />  

      {/* <div className="app__donate-now-bg">
        <div className='app__dontate-now-bg-containe'>
          <h1>Donate to Treasure Trees</h1>
          <p>Your donation helps treasure trees plant more trees and employ lacal communities </p>
        </div>
        
      </div>  

     
      <div className='donate-me-content'>
        <h1>What we are trying to achieve Together</h1>
        <p>To make up for lost revenue during the covid Pandemic, we’ve set a goal of raising US$20,000, which will allow us to fulfill our immediate mission:</p>
        <ol>
          <li>Provide reforestation employment to community members in need while planting all 40,000 saplings</li>
          <li>Offer our local youth the opportunity to plant trees for education scholarships</li>
          <li>Create safe jobs that follow social distancing guidelines</li>
          <li>Empower the next generation of indigenous Guatemalans to stand up for their rights and take pride in their heritage</li>
          <li>Produce oxygen to reverse global climate change and to ensure that our children have clean air to breathe</li>
        </ol>

      </div> 

      <div className="app__donate-now-app">
        <Link to="/donate/donate-to-treasure-trees"><h1>- Click here to support Treasure Trees -</h1></Link>
      </div>


      <div className='content-containme'>
        <p>In these difficult times, we very much appreciate donations of all sizes. A gift that is meaningful to you is also meaningful to Chico Mendes. Please contact us with any questions and share our fundraiser with others who believe in our mission.</p>
        <p>Together we will be able to provide employment and scholarships to our community today while planting the forests of tomorrow. Your support will help us address the current socioeconomic crisis in Cantel while advancing our fight for environmental justice.</p>      
      </div>

      <div className="app__todo-dollar">
        <h1>What Can Your Dollar Do?</h1>

        <h2>$10 takes care of one new tree for one year</h2>
        <p>Planting a healthy tree with a high chance of survival takes a lot more than just a seed, soil, water, and sunlight. Donations aid us in pruning, weeding, and watering saplings in el vivero (the tree nursery), planting on the steep mountains of Cantel, clearing brush that ignites forest fires, and fertilizing new trees with natural compost.</p>
        <h2>$8 buys 1000 bags to plant aguacatillo and pinabete seedlings in the vivero</h2>
        <h2>$20 pays one field worker a fair wage for one day of work</h2>
        <h2>$40 buys a new wheelbarrow to transport trees, compost, and soil</h2>
        <h2>$70 provides snacks for 30 local Pachaj student volunteers </h2>
        <p>When we break for snacks after a morning of hard work, some students have nothing to eat. Your donation ensures that no local student goes hungry while volunteering with Chico Mendes.</p>
        <h2>$80-500 supports one day of work at a reforestation site</h2>
        <p>Planting and continual maintenance of reforestation sites requires transporting people, trees, and tools to the site, as well as hiring local workers to aid Pachaj students and international volunteers with manual labor. Daily expenses vary, depending on the site's seasonal reforestation phase.</p>
        <h2>$4350 builds a new greenhouse to protect saplings from damaging frost</h2>
        <p>Without proper protection, young aguacatillo and aliso trees die during the cool season (Dec-Mar). Each November, we construct roofs from scrap wood and corn stalks to protect the saplings. Unfortunately, our impermanent roof structures require constant repairs and prevent trees from receiving enough sunlight.</p>
      </div> */}

      {/* Donation Header Section */}


      {donateheader.map((item, index) =>(

    
        <div className='app__content-head-donate' key={index}>
          <div className="done-content">
            <div className='condon'>
              <h1>{item.title}</h1>
              <h2>{item.date}</h2>
              <p>{item.description}</p>
              <div className="done-btn">
                <Link to="/donate-to-treasure-trees"  ><button className="one">Make a Donation</button></Link>
                <Link  to="/donate-to-treasure-trees" ><button className="two">Monthly Donation</button></Link>
              </div>
            </div>
            <div className='conner-cocilas'>
              {social.map((item, index) =>(
                <div className='social' key={index}>
                  <a href={item.linkUrl} rel='noopener noopenner' target="_blank"><img src={item.imgUrl} alt="" /></a>
                </div>
              ))}
            </div>
          </div>



          <div className="done-img">
            <img src={urlFor(item.imgUrl)} alt="" />
          </div>
        </div>

      ))}

      {/* Donation Content Section */}


        {donatequestions.map((donitem, index) =>(
          
          <div className='aonctont' key={index}>

        <div className='app__dondner-cont-now'>
          <h1>{donitem.queation1}</h1>
          <p>{donitem.answer1}</p>
        </div>


        <div className='app__content-donationn-container'>

        {donationcards.map((item, index) =>(

        <div className="app__doner-conetnt-wrap" key={index}>

          <img src={urlFor(item.imgUrl)} alt="" />
          <div className='cntont'>
            <h1>{item.title}</h1>
            <p>{item.description}</p>
            <div className="conco-btn">
              <a href={item.buttonurl} rel="noopener noreffer" target="_blank"><button>{item.buttonname}</button></a>
            </div>
          </div>

        </div>

        ))}
      </div>


      <div className='app__donate-questions'>
          <h1>{donitem.question2}</h1>
          <p>{donitem.answer2}</p>
          <h1>{donitem.question3}</h1>
          <ul>
            <li>{donitem.answer1question3}</li>
            <li>{donitem.answer2question3}</li>
            <li>{donitem.answer3question3} </li>
          </ul>

          <h1>{donitem.question4}</h1>
          <ul>
            <li>{donitem.answer1question4}.</li>
            <li>{donitem.answer2question4}.</li>
          </ul>

          <h1>{donitem.question5}</h1>
          <p>{donitem.answer5}</p>
      </div>


      </div>
      
      ))}


      <Footer/>
    </>
  )
}

export default Donate