
import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'

import { images } from '../../constants';
import './Navbar.scss';
import { RiArrowDropDownLine } from 'react-icons/ri';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="app__navbar primary-navigation">
      <div className="app__navbar-logo">
        <Link to="/"><img src={images.logo5} alt="logo" /></Link>
      </div>
      <ul className="app__navbar-links menu big">
            <li className="menu-items"><a>Company  <RiArrowDropDownLine/></a>
            <ul className="dropdown">
                  <li className="menu-item"><a href="/#about">About Us</a></li>
                  <li className="menu-item"><Link to="/ourhistory/projecthistory/history-time-line">Project History</Link></li>
                  <li className="menu-item"><Link to="/aboutus/meet-the-team">The Team</Link></li>
                </ul>
            </li>
          
          <li className="menu-items"><a>Work <RiArrowDropDownLine/></a>
              <ul className="sub-menu">
                <li className="menu-item"><Link to="/ourwork/trees-and-plants">Agroforestry : Meet The Trees & Crops</Link></li>
                      <li className="menu-item"><Link to="/ourwork/regenerative-agriculture">Regenerative Agriculture Pracitces</Link></li>
                      <li className="menu-item"><Link to="/ourwork/community-outreach-programs/">Community Outgrown Programs</Link></li>
              </ul>
           </li>
           <li className="p__opensans"><a href="/#progress">Progress</a></li>
           <li className='menu-items'><a >Camp <RiArrowDropDownLine/></a>
           <ul className="sub-menu">
              <li className="menu-item"><a>Meet The Camp </a></li>
              <li className="menu-item"><a>Restoration Activities</a></li>
              <li className="menu-item"><a>Learning Program & Cost</a></li>
            </ul>
           </li>
          <li className="p__opensans"><Link to="/treasure-trees-memeories">Gallery</Link></li>
           <li className="menu-items"><Link to="/contact">Contact</Link>
          </li>
      </ul>
      <div className="app__navbar-donate-now">
      <Link to="/donate"><button>Donate</button></Link>
      </div>


      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul className="menu-small">
                <li className="menu-items"><a>Company  <RiArrowDropDownLine/></a>
                <ul className="dropdown">
                      <li className="menu-item"><a href="/#about">About us</a></li>
                      <li className="menu-item"><Link to="/ourhistory/projecthistory/history-time-line">Project History</Link></li>
                      <li className="menu-item"><Link to="/aboutus/meet-the-team">The Team</Link></li>
                    </ul>
                </li>
                <li className="menu-items"><a>Work  <RiArrowDropDownLine/> </a>
                    <ul className="sub-menu">
                      <li className="menu-item"><Link to="/ourwork/trees-and-plants">Agroforestry : The Crops & Trees</Link></li>
                      <li className="menu-item"><Link to="/ourwork/regenerative-agriculture">Regenerative Agriculture Pracitces</Link></li>
                      <li className="menu-item"><Link to="/ourwork/community-outreach-programs/">Community Outgrown Program</Link></li>
                    </ul>
                </li>
                <li ><a href="/#progress">Progress</a></li>
                <li ><Link to="/treasure-trees-memeories">Gallery</Link></li>
                <li className="menu-items"><a>Camp</a>
                </li>
                <li className="menu-items"><Link to="/contact">Contuct Us</Link> </li>
                <li className="menu-items"><Link to="/donate">Donate</Link> </li>

            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;







