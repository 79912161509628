import React from 'react';
import {Link} from 'react-router-dom';
import './Error.css';

const Error = () => {
  return (
    <div className="app__error-page">
        <h1 className='p__opensans' style={{fontSize:"30px", color: "red"}}>404</h1>
        <p className='p__opensans headtext__cormorant' style={{fontSize:"40px", color:"black", paddingTop: "4rem"}}>Page Not Found</p>
        <button className="custom__button" type="button"><Link to="/">Go home</Link></button>
        
    </div>
  )
}

export default Error