import React from 'react';
import { Container, Typography, Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import CartItem from './CartItem/CartItem';
import useStyles from './styles';
import './Cart.scss'

const Cart = ({ cart, onUpdateCartQty, onRemoveFromCart, onEmptyCart }) => {
  const classes = useStyles();

  const handleEmptyCart = () => onEmptyCart();

  const renderEmptyCart = () => (
    <div className="choo-amoutn-now">
      <p className='content-p-nowww'>We noticed you did not choose an amount ,
        <Link className={classes.link} to="/">kindly choose an amount to donate. Even a dollar counts. Your donation means a lot to us, thank you</Link>!
        </p>
      </div>
  );

  if (!cart.line_items) return 'Loading';

  const renderCart = () => (
    <>
      {/* <Grid container spacing={3}>
        {cart.line_items.map((lineItem) => (
          <Grid item xs={12} sm={4} key={lineItem.id}>
            <CartItem item={lineItem} onUpdateCartQty={onUpdateCartQty} onRemoveFromCart={onRemoveFromCart} />
          </Grid>
        ))}
      </Grid> */}
      <div className='app__cart-chosene'>
        {cart.line_items.map((lineItem) => (
          <div item  key={lineItem.id} className="one">
            <CartItem item={lineItem} onUpdateCartQty={onUpdateCartQty} onRemoveFromCart={onRemoveFromCart} />
          </div>
        ))}
      </div>
      <div className="app__detaile-paymn">
        <h1 >Subtotal: {cart.subtotal.formatted_with_symbol}</h1>
        <div>
          <Button className={classes.emptyButton} size="large" type="button" variant="contained" color="secondary" onClick={handleEmptyCart}>Clear Amount</Button>
          {/* <Button className={classes.checkoutButton} component={Link} to="/checkout" size="large" type="button" variant="contained" color="primary">Checkout</Button> */}
        </div>
      </div>
    </>
  );

  return (
    <div className="app__contri-infp">
      {/* <div className={classes.toolbar} /> */}
      <p>Contribution Amount</p>
      { !cart.line_items.length ? renderEmptyCart() : renderCart() }

    </div>
  );
};

export default Cart;
