import React from 'react'

import { Navbar} from '../../components';
import Footer from '../../container/Footer/Footer'
import TopCart from '../Top/TopCart';
import Trees from './Forestry/TreesCrops/Trees';
const OurWork = () => {
  return (
    <div>
      <Navbar/>
      <TopCart/>
      <Trees/>
      <Footer/>
    </div>
  )
}

export default OurWork