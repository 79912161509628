import React, { useState, useEffect } from 'react';
import { client, urlFor } from '../../client';

import { MotionWrap } from '../../wrapper';


import './SocialBenefit.scss'
import { GrGroup } from 'react-icons/gr'

const SocialBenefit = () => {

  const [economicgallery, setEconomicgallery] = useState([]);

  useEffect(() => {
    const query = '*[_type == "economicgallery"]';

    client.fetch(query).then((data) =>{
      setEconomicgallery(data);
    });
   
  }, [])

  const [economicdata, setEconomicdata] = useState([]);


  useEffect(() => {
  const query = '*[_type == "economicdata"]';

  client.fetch(query).then((data) =>{
    setEconomicdata(data);
  });
 
}, [])


  return (
    <>
      <div className='app__social-container-in' id='socialbenefits'>

        {economicdata.map((item, index) =>(


        <div className="app__socialb-cont" key={index}>
          <h1>{item.title}</h1>
          <p>{item.description}</p>
          <ol>
            <li>{item.impact1}</li>
            <li>{item.impact2}</li>
            <li>{item.impact3}</li>
          </ol>
        </div>  
      ))}


        <div className='img-social-benefit'>
          {economicgallery.map((item, index)  =>(
            <div className='img' key={index}>
              <img src={urlFor(item.imgUrl)} alt={item.description} />
              <p>{item.description}</p>
            </div>
          ))}
        </div>  
    </div>
    </>
  )
}

export default MotionWrap(SocialBenefit, 'app__testimonial',
  'socialbenefits',
);

// export default SocialBenefit;