import React, { useState, useEffect } from 'react';
import { client, urlFor } from '../../client';

import { MotionWrap } from '../../wrapper';

import './Collaboration.scss'
import { MdGroup } from 'react-icons/md';

const Collaboration = () => {    
    const [collaborationsgallery, setCollaborationsgallery] = useState([]);

    useEffect(() => {
      const query = '*[_type == "collaborationsgallery"]';
  
      client.fetch(query).then((data) =>{
        setCollaborationsgallery(data);
      });
     
    }, [])

    const [collaborationscontent, setCollaborationscontent] = useState([]);

    useEffect(() => {
      const query = '*[_type == "collaborationscontent"]';
  
      client.fetch(query).then((data) =>{
        setCollaborationscontent(data);
      });
     
    }, [])
  return (
    <>
        {/* <div className="app__collab-headr" id="collaborations">
            <h1>Implementation through collaboration</h1>
            <div className="app__collab-line"/>
        </div> */}

        <div className='app__collab-container'>

            {collaborationscontent.map((item, index) =>(
                <div className='app__collab-content' key={index}>
                    <h1>Implementation through collaboration</h1>
                    <ol>
                        <li>  {item.description1}</li>
                        <li>  {item.description2}</li>
                        <li>  {item.description3}</li>
                    </ol>
                </div>
            ))}

            <div className="app__collab-imgcontent">
                {collaborationsgallery.map((item, index) =>(
                    <div className='one' key={index}>
                        <img src={urlFor(item.imgUrl)} alt={item.description} />

                        <p>{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
    </>
  )
}

// export default Collaboration

export default MotionWrap(Collaboration, 'app__testimonial',
  'economic',
);
