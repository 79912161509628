import React from 'react'
import { Card, CardMedia, CardContent, CardActions, Typography, IconButton } from '@material-ui/core';
import { AddShoppingCart, DoneAllRounded } from '@material-ui/icons';
import {BiDonateHeart} from 'react-icons/bi'

import './Product.scss'

import useStyles from './styles';

const Product = ({ product, onAddToCart}) => {
  const classes = useStyles();
  const handleAddToCart = () => onAddToCart(product.id, 1);

  return (
    <>
        {/* <Card className={classes.root}> */}
            {/* <CardMedia className={classes.media} image={product.image.url} title={product.name} /> */}
            {/* <CardContent> */}
            <div className='app__conent-proreoeoru'>
                <div >
                    {/* <Typography gutterBottom variant="p" component="h2">
                        {product.name}
                    </Typography> */}
                    {/* <Typography gutterBottom variant="h4" component="h2"> */}
                      <div className="app__prodprocesss">
                        {product.price.formatted_with_symbol}
                      </div>
                    {/* </Typography> */}
                </div>
                {/* <Typography dangerouslySetInnerHTML={{ __html: product.description }} variant="body2" color="textSecondary" component="p" /> */}
            {/* </CardContent> */}

            {/* <CardActions disableSpacing > */}
              <IconButton aria-label="Add to Cart" onClick={handleAddToCart}>
                <BiDonateHeart />
              </IconButton>
            {/* </CardActions> */}

          </div>
        {/* </Card> */}
    </>
  )
}

export default Product