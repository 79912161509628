import React from 'react';
import { BsInstagram, BsTwitter, BsYoutube, BsLinkedin} from 'react-icons/bs';
import { FaFacebook,} from 'react-icons/fa';

import { Link } from 'react-router-dom';

import './Footer.scss';
import { images } from '../../constants';

const Footer = () => (
  <div className="app__footer ">
    <div className='app__footer-liner-in'/>
    <div className="app__footer-links">
      <div className="app__footer-links_logo">
      <div className='header-inf-line'>
          <h1>Navigate</h1>
          <div className='linerme'/>
        </div>
        <Link to="/">Company</Link>
        <a href="/#about">About Us</a>
        <Link to="/ourhistory/projecthistory/history-time-line">Project History</Link>
        <a href="/#progress">Progress</a>
        <a href="/aboutus/meet-the-team">Meet The Team</a>
      </div>
      <div className='app__footer-links_contact'>
        <div className='header-inf-line'>
          <h1>Contact</h1>
          <div className='linerme'/>
        </div>
        <Link to="/contact">Contact</Link>
        <a href="mailto:treasuretreeskenya@gmail.com">treasuretreeskenya@gmail.com</a>
        <a>Malindi Kenya</a>
        <a href="tel:+254725707814"> (+254)725707814</a>
      </div>
      <div className="app__footer-links_work">
      <div className='header-inf-line'>
          <h1>Our Work</h1>
          <div className='linerme'/>
        </div>
        <a>The Camp</a>
        <Link to="/ourwork/trees-and-plants">Agroforestry and: The Trees & Crops</Link>
        {/* <a href="/ourwork/agroforestry-and-reforestation/">Agroforestry & Reaforestation</a> */}
        <Link to="/ourwork/regenerative-agriculture">Regenerative Agriculture Practices</Link>
        <Link to="/ourwork/community-outreach-programs/">Community Outgrown Programs</Link>
      </div>
    </div>



    <div className="app__liner">
    </div>

    <div className="footer__copyright">
      <div className='copy'>
        <p>&copy;2022 TreasureTrees Kenya. With ❤️ from Malindi, Kenya.</p>
      </div>
      <div className='logo'>
        <Link to="/"><img src={images.logo4} alt="logo" /></Link>
      </div>
      <div className="socials">
          <a href="https://www.facebook.com/treasuretreeskenya/" target="_blank" rel='noreferrer'><FaFacebook/></a>
          <a href="https://www.instagram.com/treasure.trees/" rel='noreferrer' target="_blank"><BsInstagram/></a>
          <a href="https://www.linkedin.com/in/treasure-trees-307027246/" target="_blank" rel='noreferrer'><BsLinkedin/></a>
          <a href="https://twitter.com/treasure_trees" target="_blank" rel='noreferrer'><BsTwitter/></a>
          <a href="https://www.youtube.com/channel/UCXnvff5IqnGeFv0Cht-RwJg/featured" target="_blank" rel='noreferrer'><BsYoutube/></a>
      </div>
    </div>
  </div>
);

export default Footer;
