import React from 'react'
import './AboutNew.scss'
import ImpactMap from './Map/ImpactMap'
import DataProgress from './DataProgress/DataProgress'

const AboutNew = () => {
    
  return (
    <>
        <div className='app__aboutnew-container'>
            

            <div className="app__aboutnew-head">
                <h1>Our Impact </h1>
                <div className='app__aboutnew-liner'/>
            </div>
            
        </div>
        <ImpactMap/>
        <DataProgress/>
    </>
  )
}

export default AboutNew