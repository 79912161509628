import React from 'react'

import explore from '../../../assets/explore.jpg';
import './Activity.scss'

const Activity = () => {
    
  const activity = [
    {
      pic: explore,
      price: "11 Days From KSH. 5000",
      title: "Vienna and Combodia Pirate Expedition",
      type: 'Trip type: Expedition',
      level: 'Activity Level: Light'
    },
    {
      pic: explore,
      price: "11 Days From KSH. 5000",
      title: "Vienna and Combodia Pirate Expedition",
      type: 'Trip type: Expedition',
      level: 'Activity Level: Light'
    },
    {
      pic: explore,
      price: "11 Days From KSH. 5000",
      title: "Vienna and Combodia Pirate Expedition",
      type: 'Trip type: Expedition',
      level: 'Activity Level: Light'
    },
  ]
  return (
    <>
        <div className='app__ourcamp-inspiration'>
          <h1 className="after">NEW & REDESIGNED ACTIVITIES</h1>
        </div>
        <div className="app__ourcamp-profiles">
          { activity.map((task, index)=>(

          
          <div className="app__ourcamp-profile_item" key={task.name + index}>

            <div className='app__ourcamp-cards'>
                <div className='card-img'>
                <img src={task.pic} alt={task.title} />
                <div className="tripcard-badge">Featured</div>
                </div>
                <div className="cards-price">
                  <div className='line'/>
                  <div className='line'/>
                  <p>{task.price}</p>
                </div>
                <div className='cards-content'> 
                  <h1>{task.title}</h1>
                  <p>{task.type}</p>
                  <p>{task.level}</p>
                </div>

            </div>
          </div>
          ))}
        </div>
    </>
  )
}

export default Activity