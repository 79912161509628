import React, {useEffect, useState} from 'react'
import {Navbar,Products,Cart} from "../index"
import { commerce} from '../../../lib/commerce'


const Thecart = () => {

    
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState({});


  
  const fetchProducts = async () => {
    const { data } = await commerce.products.list();
      setProducts(data);
  };

  const fetchCart = async () => {
    setCart(await commerce.cart.retrieve());
  };

    
  const handleAddToCart = async (productId, quantity) => {
    const item = await commerce.cart.add(productId, quantity);

    setCart(item.cart);
  };

  
  const handleUpdateCartQty = async (productId, quantity) => {
    const {cart} = await commerce.cart.update(productId, { quantity });

    setCart(cart);
  };

  const handleRemoveFromCart = async (productId) => {
    const {cart} = await commerce.cart.remove(productId);

    setCart(cart);
  };

  const handleEmptyCart = async () => {
    const {cart} = await commerce.cart.empty();

    setCart(cart);
  };


  useEffect(() => {
    fetchProducts();
    fetchCart();
  }, []);

  console.log(cart)
  return (
        <>
            <Navbar />
            <Cart cart={cart} 
            handleUpdateCartQty={handleUpdateCartQty}
            handleRemoveFromCart={handleRemoveFromCart}
            handleEmptyCart={handleEmptyCart}

            /> 
        </>
  )
}

export default Thecart