import React from 'react'
import { BsArrowRight } from 'react-icons/bs'

import './Letter.scss'
import sunset from '../../../assets/use/sunset.jpg'
import right from '../../../assets/right.svg'


const Letter = () => {
  return (
    <>
        <div className="app__letter-container">
            {/* <img src={sunset} alt="" /> */}
            <div className="app__letter-camp">
                <h1>BECOME PART OF OUR STORY</h1>
                <p className="p-one">Becoming a member of the Treasure Trees community comes with a whole host of benefits.</p>
                <p className='p-two'> Each month, we personally select and curate our favourite programs and featured activities share them exclusively with our newsletter subscribers.
                Enjoy exclusive access to our offers, the latest news and competitions!</p>
            </div>
            <div className="app__letter-form">
                <input type="text"  placeholder="Enter your email"/>
                <div className="bg">
                    <BsArrowRight/>                    
                </div>
            </div>
            <div className="condition">
                <p>By subscribing to the newsletter, 
                I declare to have read and understood the Privacy Policy of Treasure Trees Company Ltd.</p>
            </div>
            <div className="check-box">
                <input type="checkbox" />
                <p>I'm over 16 years ols</p>
            </div>
        </div>
    </>
  )
}

export default Letter