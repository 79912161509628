import React, {useState, useEffect} from 'react'
import { urlFor, client } from '../../../../../client'

import './TheCrops.scss'


const TheCrops = () => {

const [thetreesandcrops, setThetreesandcrops] = useState([]);

useEffect(() => {
  const query = '*[_type == "thetreesandcrops"]';

  client.fetch(query).then((data) =>{
    setThetreesandcrops(data);
  });
 
}, [])

  return (
    <>
        <div className="app__thecrops">
            <h1> Meet The Trees & Crops</h1>
            <div className='app__teress-crops'/>
        </div>
        <div className='app__thecrops-wrapper'>
            {thetreesandcrops.map((item, index) =>{
                return(
                    <div className='thecrops-data' key={index}>
                    
                            <div className='app__data-details'>
                                <h1>- {item.title} -</h1>
                                <p>{item.information}</p>
                                {/* <div className='btn-teres'>
                                    <button>{item.btnUrl}</button>
                                </div> */}
                            </div>
                            <div className='app__img-treesinfo'>
                                <img
                                    whileInView={{opacity: 1}}
                                    whileHover= {{scale: 1.035}}
                                    transition = {{duration: .6, type: "tween"}} 
                                    src={urlFor(item.imgSource)} 
                                    alt={item.title} 
                                />
                                <div className='app__info-trees'>
                                    <h1>{item.heading}</h1>
                                    <p>{item.description}</p>
                            
                                </div>
                            </div>
                    </div>
                )
            })}

        </div>

        {/* <div className='app__crops-detail-btn'>
            <Link to="/ourwork/trees-and-plants/the-trees-indiginous-species/"><button> Explore more <BiChevronRight/></button></Link>
        </div> */}
        {/* <Socials/> */}
    </>
  )
}

export default TheCrops