import React, {useEffect, useState} from 'react'
import {Navbar,Products,Cart} from "../index"


import { commerce} from '../../../lib/commerce'


import Checkout from '../CheckoutForm/Checkout/Checkout'

const TheCheckout = () => {

  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState({});
  const [order, setOrder] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  
  const fetchProducts = async () => {
    const { data } = await commerce.products.list();
      setProducts(data);
  };

  const fetchCart = async () => {
    setCart(await commerce.cart.retrieve());
  };

    
  // const handleAddToCart = async (productId, quantity) => {
  //   const {cart}= await commerce.cart.add(productId, quantity);

  //   setCart(cart);
  // };
  const handleAddToCart = (productId, quantity) => {
    commerce.cart.add(productId, quantity).then((item) => {
      setCart(item.cart);
    }).catch((error) => {
      console.error('There was an error adding the item to the cart', error);
    });
  }
  
  const handleUpdateCartQty = async (productId, quantity) => {
    const {cart} = await commerce.cart.update(productId, { quantity });

    setCart(cart);
  };

  const handleRemoveFromCart = async (productId) => {
    const {cart} = await commerce.cart.remove(productId);

    setCart(cart);
  };

  const handleEmptyCart = async () => {
    const {cart} = await commerce.cart.empty();

    setCart(cart);
  };
  
  const refreshCart = async () => {
    const newCart = await commerce.cart.refresh();

    setCart(newCart);
  };

 
  const handleCaptureCheckout = async (checkoutTokenId, newOrder) => {
    try {
      const incomingOrder = await commerce.checkout.capture(checkoutTokenId, newOrder);

      setOrder(incomingOrder);

      refreshCart();
    } catch (error) {
      setErrorMessage(error.data.error.message);
    }
  };



  
  useEffect(() => {
    fetchProducts();
    fetchCart();
  }, []);

  console.log(cart)
  return (
    <>
      <Checkout 
      cart={cart}
      order={order} 
      onCaptureCheckout={handleCaptureCheckout} 
      error={errorMessage} 
      />
    </>
  )
}

export default TheCheckout