import React from 'react'
import './AboutCamp.scss'

import apple from '../../../assets/use/apple.webp'
import gardening from '../../../assets/use/gardening.webp'
import sunset from '../../../assets/use/sunset.jpg'
import oranges from '../../../assets/use/oreanges.jpg'
import vietnam from '../../../assets/use/vietnam.jpg'
import commnuity from '../../../assets/photos/teamwork1.jpeg'
import child1 from '../../../assets/photos/child1.jpg'
import commnuity1 from '../../../assets/photos/community1.jpeg'
// import sunset from '../../../assets/use/sunset.jpg'




const AboutCamp = () => {

  const data = [
    { 
      image: vietnam,
      title: 'The Difference !',
      desc: "National Geographic Expeditions offers premium travel experiences that embody the renowned National Geographic spirit of adventure, conservation and education, taking curious travellers across the globe.",
    },
    { 
      image: gardening,
      title: "Giving Back",
      desc: "National Geographic Expeditions offers premium travel experiences that embody the renowned National Geographic spirit of adventure, conservation and education, taking curious travellers across the globe.",
    },
    { 
      image: child1,
      title: "Giving Back",
      desc: "National Geographic Expeditions offers premium travel experiences that embody the renowned National Geographic spirit of adventure, conservation and education, taking curious travellers across the globe.",
    },
    // { 
    //   image: commnuity1,
    //   title: "The Community",
    //   desc: "National Geographic Expeditions offers premium travel experiences that embody the renowned National Geographic spirit of adventure, conservation and education, taking curious travellers across the globe.",
    // },
    // { 
    //   image: commnuity,
    //   title: "Our Experts",
    //   desc: "National Geographic Expeditions offers premium travel experiences that embody the renowned National Geographic spirit of adventure, conservation and education, taking curious travellers across the globe.",
    // },
  ]
  return (
    <>
        <div className="app__aboutcamp-head">
            <h1>About The Camp</h1>
        </div>

        <div className="app__aboutcamp-wrapper">
            <div className="app__aboutcontent">
                <h1>DISCOVER THE DIFFERENCE WE MAKE</h1>
                <p>See the world through our eyes as you travel with Treasure Trees to our Eco Facility.
                Our trips have each been individually
                designed to immerse you in the heart of the places and cultures you visit.
                </p>
            </div>
            <div className="app__aboutcamp-cards">
              {data.map((item, index)=>{
                return(
                  <div key={index} className="content">
                    <div className='contain'>
                      <img src={item.image} alt="image" />
                      <h1>{item.title}</h1>
                    {/* <p>{item.desc}</p> */}
                    </div>
                  </div>
                )
              })}
            </div>
        </div>
    </>
  )
}

export default AboutCamp