import React, { useState, useEffect } from 'react';
import { client, urlFor } from '../../client';


import './NewProgress.scss'

import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import { BiDonateHeart } from 'react-icons/bi'
import { MotionWrap } from '../../wrapper';

const NewProgress = () => {

    const [progress, setProgress] = useState([]);

    useEffect(() => {
      const query = '*[_type == "progress"]';
  
      client.fetch(query).then((data) =>{
        setProgress(data);
      });
     
    }, [])

  return (
    <>
        <div className="app__newprogress-head" id="progress">
            <h1>Progress</h1>
            <div className="progreess_line"/>
        </div>

        <div className='app__instr'>
            <p>Hover or click on images  👇</p>
        </div>
        <div className="app__newprogrees_container">
            {progress.map((item, index) =>(
                <div className='card' key={index}>

                    <div className="app__img-cont">
                        <img src={urlFor(item.imgUrl)} alt={item.title} />
                    </div>
                    <h1>{item.title}</h1>
                    <div className="content">
                        <p>{item.description}</p>
                        <div className='btn'>
                           <button><Link to={item.linkUrl}>{item.btnUrl} </Link></button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
{/* 
        <div className='app__newprogress-btns'>
            <button className='twoo'><Link to="/donate">Donate <BiDonateHeart/></Link></button>
        </div> */}
    
    </>
  )
}


export default MotionWrap(NewProgress, 'app__testimonial',
  'socialbenefits',
);

// export default NewProgress;