import React from 'react'

import TheGallery from './TheGallery'

import './PhotoGallery.scss'

const PhotoGallery = () => {

  

  return (
    <>
        <div className="photogallery-gallery">
            <TheGallery />

            <div className="rights"> 
                {/* <h1>&copy;2022 treasuretrees all rights reversed</h1> */}
            </div>
        </div>
    </>
  )
}

export default PhotoGallery