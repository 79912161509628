import React ,{ useState} from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import {BiChevronRight} from 'react-icons/bi'
import {Link} from 'react-router-dom';


import './Video.scss';


const Video = () => {

  return (
    <>
    <div className='app__video-headme'>
      <h1>Social <span>Video</span></h1>
      <div/>
    </div>
    {/* <div className="app__content-info_video">
      <p>Climate change is the biggest threat to humankind. Many semi-arid and arid areas
         are suffering from the consequences of deforestation and a lack of land and water management. 
        We believe that the negative spiral of climate change can only be reversed by means of 
        regenerative agroforestry practices and by offering alternative income earning activities to local communities. 
        Especially by focusing on arid and semi-arid areas, we believe that we can not only contribute to climate change
         adaptation, but also to climate change mitigation. By planting trees and other drought resistant crops we can
        bring degenerated ecosystems back to life
        
      </p>
    </div> */}
    <div className="app__content-container_video">
      {/* <div className='btn-now'>
        <a href="https://www.youtube.com/channel/UCXnvff5IqnGeFv0Cht-RwJg/featured" target="_blank" rel="noopener noreferrer"><h2>Learn more <BiChevronRight/></h2></a>
      </div> */}
      <iframe 
      src="https://www.youtube.com/embed/jAa58N4Jlos" 
      frameBorder="0" 
      title  = "Treasure Trees"
      allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      >
    </iframe>
    </div>
  </>
    )
  }

   
  export default AppWrap(
    MotionWrap(Video, 'app__testimonial'),
    'introvideo',
    'app__whitebg',
  );
  