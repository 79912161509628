import React from 'react'
import {Link} from 'react-router-dom'
import './OurProgress.scss'
import community from '../../assets/community.jpg'
import { AppWrap, MotionWrap } from '../../wrapper';
import { BsArrowRight } from 'react-icons/bs'


const OurProgress = () => {
  return (
    <>
        <div className="app__whereweare-head" id="where-we-are">
            <h1>Progress Today</h1>
            <div/>
        </div>
        
        <div className="app__whereweare-bg">
            <h1>Where we are</h1>
            <div/>
            <p>Treasure trees has made a lot of break through over the years slice our launch in 2017. We were able to help  the community by creating empowerment & employment to the youth
            We are also funding the construction of the dams to help the draught prone areas around Kenya Baricho area which is mostly arid.</p>
        </div>

        <div className="app__whereweare-content-inner">
            <ol>
                <p>Treasure Trees, with the help of the team has been able to create empowerment to the local community and. We have been able to educate them on the importance of planting trees and ofcourse ohter draught resistant crops so they are able to sustaine themselves in the long run</p>
                <p>We have been able to fund the contruction of the second Dam on the run. This is to help the community in their daily activities like watering their crops and for daily usage.</p>
                <p>Over the course of the years, we were also able to create a Eco Resotoration Camp where all are welcome. We are a community and we love meeting new people and making new friends !😊</p>
                <p>In our <Link to="/camp"> Eco Camp</Link> we engage in different activities like farming together, crop rotation , camping, story telling, community tour by the local coordinator among other stuff.</p>
                {/* <Link to="/camp"><p>See more here ...</p></Link> */}
            </ol>
        </div>
        <div className='whereweare-btn'>
            <Link to="/aboutus/where-we-are-today"><button>Explore <BsArrowRight/></button></Link>
        </div>
    </>
  )
}

// export default OurProgress

export default MotionWrap(OurProgress, 'app__testimonial',
  'ourhistory',
);
