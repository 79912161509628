import React from 'react';
import { images } from '../../constants';
import {SubHeading} from '../../components';
import { ImQuotesLeft,ImQuotesRight } from 'react-icons/im';
import { useScroll} from '../../components/ScrollTop/UseScroll';
import {BsFacebook, BsInstagram, BsTwitter, BsYoutube, BsLinkedin, BsFillPlayFill} from 'react-icons/bs';
import { motion } from 'framer-motion';
import { headerAnimation } from '../../animation';
import Bob2 from '../../assets/bob2.jpg';
import outlook from '../../assets/outlook.jpg';
import child from '../../assets/child.jpg';
import AppWrap from '../../wrapper/AppWrap';


import './Header.scss';


const Header = () => {
  const [element, controls] = useScroll(); 
  return(
    <>
    <div>
      <h1 className="head-text">About Treasure <span>Tree</span></h1>
      {/* <h2 className="app__header-text">Making of <span>Treasure </span> Trees</h2> */}
      <div className="app__underline"/>
      <div className="app__header-elements" id="header">
        <motion.div
        className="app__container-content"
        >
          <h2 className="">Know us from our very humble beginings.</h2>
          <p className="p-text">Start or extend your team with vetted, nearshore developers, designers & product managers.</p>
          <motion.div
          className="app__image-details app__flex"
          >
            <img src={child} alt="" />
          </motion.div>
        </motion.div>
      </div>
    </div>
    </>
    // <motion.div className id="header" ref={element}
    //   variants={headerAnimation}
    //   animate= {controls}
    //   transition = {{
    //     delay: 0.03,
    //     type:"tween",
    //     duration: 0.8,
    //   }}
    // >
    //   <div className="app__links-icons">
    //   <div className="app__flex app__wrapper-info">
    //     <h1 className='head-text '>Our <span style={{color: "#00c3ff"}}>Socials</span> Links</h1>
    //     <div className="app__links-line"/>
    //   </div>
    //   <div className="app__links-icon_svg">
    //    <BsFacebook/>
    //    <BsTwitter/>
    //    <BsInstagram/>
    //    <BsLinkedin/>
    //    <BsYoutube/>
    //   </div>
    //  </div>
    //   <div className="app__wrap flex__center">
    //     <img src={Bob2} alt="header_img" />
    //   </div>
    //   <div className="app__contents ">
    //     <div className="app__content-format flex__center " style={{margin: "2rem 0"}}>
    //       <h1 className="app__content-format_name">Bob Van der Bijl</h1>
    //       <h1 className="p__opensans app__founder flex__center" >- CEO & Founder</h1>
    //     </div>
    //     <div className="app__header-content">
    //       <div className="app__header-content_quote">
    //         <ImQuotesLeft/>
    //         <p className="" >Semi-arid areas are suitable for tree planting, provided the right tree types are selected and the right propagation,
    //          planting and maintenance methods are used. <br /> We at Treasure Trees have the best tree knowledge! 
    //          Our project is a proof of concept and we want people to invest more in this type of activity. Fight climate change and deforestation through an investment that brings good revenues in the long run!
    //         </p>
    //       </div>
    //     </div>

    //   </div>

    // </motion.div>
  );
}

export default AppWrap(Header, "Header");
