import React, {useRef, useState} from 'react';

import { AppWrap, MotionWrap } from '../../wrapper';
import {BsFillPlayFill, BsPauseFill} from 'react-icons/bs'

import video from '../../assets/video.mp4';

import './Vision.scss';


const Vision = () => {

  const [playVideo, setPlayVideo] = useState(false);
  const vidRef = useRef();

  const handleVideo = () =>{
    setPlayVideo((previousPlayVideo) => !previousPlayVideo);
    if(playVideo){
      vidRef.current.pause();
    }
    else{
      vidRef.current.play();
    }
  }


  return (
    <>
    <div className="app__vision-header">
      <h2>Our Goals & Vision!</h2>
    </div>
    <div className="app__vision-container">

        <div className="app__vision-content">        
          {/* <p><span style={{color: "#fe5f63"}}> Climate change </span> is the biggest threat to humankind.
             Many semi-arid and arid areas are suffering from 
             the consequences of <span style={{color: "#fe5f63"}}>deforestation </span>  and a lack of land 
             and water management. We believe that the negative 
             spiral of climate change can only be reversed by means
            of <span style={{color: "#fe5f63"}}> regenerative agroforestry </span>  practices and by offering 
            alternative income earning activities to local 
              communities. Especially by focusing on arid and 
              semi-arid areas, we believe that we can not only 
              contribute to climate change adaptation, but also to 
              climate change mitigation. By planting trees and other
              drought resistant crops we can
             bring degenerated <span style={{color: "#fe5f63"}}> Ecosystem </span>  back to life.</p> */}

             <h1>Our Vision & Goal for the project!</h1>
             <p className='two'>In Kenya, dryland areas (or ASALs – arid and semi-arid lands) make up more than <span style={{color: "#fe5f63"}}>83% </span> of the country. 
              The ASAL populations experience the lowest development
               indicators and highest incidence of poverty in the country. ASAL regions are sensitive to climate change
              
              Our objective is to : <span style={{color: "#fe5578"}}> Create a sustainable farming model that will improve the ecosystem of semi-arid areas in Africa.</span>
              Besides economic viability (profitable agroforestry activities) we want to achieve the following:
              </p>
              <p className="one">1. Implementation of regenerative agricultural practices that lead to soil improvement and that promote biodiversity</p>
              <p className="one">2. Growing of suitable crops and trees with minimal water requirements</p>
              <p className="one">3. Create local value addition by means of local processing and the productive use of by-products</p>
        </div>
        <div className="app__vision-video">
          <video 
          ref={vidRef}
          src={video}
          type = "video/mp4"
          loop
          // controls ="false"
          // muted     
          />
          <div className='app__vision-overlay app__flex'>
            <div className="app__vision-overlay_circle app__flex" onClick={handleVideo}            
            >
            {playVideo
              ? <BsPauseFill />
              :<BsFillPlayFill/>
            }
            </div>
          </div>
          
        </div>

    </div>
    </>
  )
}


export default AppWrap(
    MotionWrap(Vision, 'app__testimonial'),
    'vision',
    'app__whitebg',
  );