import React, { useState, useEffect } from 'react';
import { client, urlFor } from '../../../client';

import { MapContainer,Marker,Popup,TileLayer} from 'react-leaflet'
import L from 'leaflet'

// import tree from "../../../assets/tree-icon.png"


import 'leaflet/dist/leaflet.css'

import './ImpactMap.scss'

function getIcon(icon_size){
  return L.icon({
      iconUrl: require("../../../assets/tree-icon.png"),
      iconSize: [icon_size]
    })
  }

const Map = () => {

  const position = [-3.2196104154596124, 40.11588700044775]

  
  const [ourmap, setMap] = useState([]);

  useEffect(() => {
    const query = '*[_type == "ourmap"]';

    client.fetch(query).then((data) =>{
      setMap(data);
    });
   
  }, [])



  return (
    <>
    {/* <div className="app__get-here">
      <h1>How to get here</h1>
      <div className='app__howed'/>
    </div> */}
    <div className='app__map-containerimpact'>
      <MapContainer
        center={position} 
        zoom={10}
        className="map-wrap"
      >
        <TileLayer
          // url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

          url= "https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=3K1Jp5leurjC6Gaj4ze9"
          attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        />
        {ourmap.map((locate,index) =>(

        <Marker position={locate.position} icon={getIcon(13)} key={index} >
          <Popup>{locate.marker}</Popup>
        </Marker>

        ))}

      </MapContainer>
    </div>
    </>
  )
}

export default Map