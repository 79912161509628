import React from 'react'
import {MdLocationOn,MdGroups, MdTravelExplore, MdGroupWork} from 'react-icons/md'
import {HiCubeTransparent} from 'react-icons/hi'
import {GiCircleForest} from 'react-icons/gi'
import {Link} from 'react-router-dom'
import './MissionVision.scss'
import { AppWrap, MotionWrap } from '../../../wrapper'

const Bg = () => {

    const missionvission = [
        {
            title1: 'Mission & ',
            title2: 'Vision',
            desc1: 'Develop commercial agroforestry projects in Kenya that contribute positively to the eco-system and that create socio-economic benefits for the environment in which we execute our projects.',
            desc2: 'In Kenya, dryland areas (or ASALs – arid and semi-arid lands) make up more than 83% of the country. The ASAL populations experience the lowest development indicators and highest incidence of poverty in the country. ASAL regions are sensitive to climate change Our objective is to',
            title3: 'Create A Sustainable Farming Model That Will Improve The Ecosystem Of Semi-Arid Areas In Africa',
            desc3: 'Besides economic viability (profitable agroforestry activities) we want to achieve the following:',
            desc4: '1.Implementation of regenerative agricultural practices that lead to soil improvement and that promote biodiversity',
            desc5: '2. Growing of suitable crops and trees with minimal water requirements',
            desc6: '3.Create local value addition by means of local processing and the productive use of by-products',
        }
    ]
  return (
    <>
    {missionvission.map((item,index) =>(
  
    <div className="app__mission-vision-containme" key={index}>
   
        <div className='app__bg-back'>
            <div className="content-now">
                <h1>{item.title1}<br /><span>{item.title2}</span></h1>
                <p>{item.desc1}</p>
            </div>
        </div>
        <div className='main-content'>
            <p>{item.desc2}</p>
            <h3>{item.title3}</h3>
            <p>{item.desc3}</p>
            <div className="diff">
                <GiCircleForest/>
                <p>{item.desc4}</p>
            </div>
            <div className="diff">
                <MdGroupWork/>
                <p>{item.desc5}</p>
            </div>
            <div className="diff">
                <MdGroups/>
                <p>{item.desc6}</p>
            </div>
        </div>

        </div>
        ))}        
    </>
  )
}

export default MotionWrap(Bg, 'app__mission-vission',
  'bg',
);