import React, { useState, useEffect } from 'react';
import { client, urlFor } from '../../../client';

import './DataProgress.scss'
const DataProgress = () => {
 
  const [mapimgdata, setMapimgdata] = useState([]);

  useEffect(() => {
    const query = '*[_type == "mapimgdata"]';

    client.fetch(query).then((data) =>{
      setMapimgdata(data);
    });
   
  }, [])
  return (
    <>
        <div className="app__dataprog">
          {mapimgdata.map((item, index) =>(
            <div className="one" key={index}>
              <div className='img'>
              <img src={urlFor(item.imgUrl)} alt={item.title} />
              </div>
              <h1>{item.title}</h1>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
    </>
  )
}

export default DataProgress