import React from 'react'

import './HeaderCamp.scss'
import explore from '../../../assets/explore.jpg';

const HeaderCamp = () => {
  return (
    <>
        <div className="app__background-header">
          <img src={explore} alt="" />
          <div className="app__overlay-camp">
          </div>
          <div className="content">
            <h1>Welcome and Explore the Wonders of Koromi River Camp!</h1>
          </div> 
        </div>
    </>
  )
}

export default HeaderCamp