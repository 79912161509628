import React, { useState, useEffect } from 'react';
import { client, urlFor } from '../../../../../client';
import './RegenProjects.scss'


const RegenProjects = () => {

    const [regenprojects, setRegenprojects] = useState([]);

    useEffect(() => {
      const query = '*[_type == "regenprojects"]';
      client.fetch(query).then((data) =>{
        setRegenprojects(data);
      });
     
    }, [])

    const [regenphotos, setRegenphotos] = useState([]);

    useEffect(() => {
      const query = '*[_type == "regenphotos"]';
      client.fetch(query).then((data) =>{
        setRegenphotos(data);
      });
     
    }, [])
  return (

    <>
    
        <div className="app__regenpro-container-ne">

            {regenprojects.map((item, index) =>(
                <div className='app__contene' key={index}>
                    <div className='img'>
                        <a href={urlFor(item.imgUrl)} target="_blank" rel='noopener noreferrer'> <img src={urlFor(item.imgUrl)} alt={item.title} /></a>
                    </div>
                    <div className="tje-cont">
                        <h1>{item.title}</h1>
                        <p>{item.description}</p>
                    </div>
                </div>
            ))}
        </div>

        <div className="regenerative-imp-model-proje">
              <p>When working with farmers, we always adapt the design of the projects to the context and goals,
                 while working towards the following core principles:</p>
                 <ol>
                    <li >Minimize soil disturbance</li>
                    <li >Keep soil covered and minimize soil loss</li>
                    <li>Conserve and rejuvenate natural resources</li>
                    <li >Reduce and replace off-farm inputs</li>
                    <li >Reduce and replace off-farm inputs</li>
                    <li>Long term viability and livelihood benefits for farmers</li>  
                </ol>
    </div>

    <div className="app__renere-headme">
        <h1>Photo Stories</h1>
        <div className='app__linert'/>
    </div>

    <div className='app__photo-sto-regepro'>
        {regenphotos.map((item,index) =>(
            <div className='one' key={index}>
                <a href={urlFor(item.imgUrl)} target="_blank" rel='noopener noreferrer'><img src={urlFor(item.imgUrl)} alt={item.desc} /></a>
            </div>
        ))}
    </div>
    </>
  )
}

export default RegenProjects