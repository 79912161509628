import React, { useState, useEffect } from 'react';
import { client, urlFor } from '../../client';

import './Cost.scss'
import { Link } from 'react-router-dom'
import { MotionWrap } from '../../wrapper';



const Cost = () => {

    const [costfactor, setCostfactor] = useState([]);

    useEffect(() => {
      const query = '*[_type == "costfactor"]';
  
      client.fetch(query).then((data) =>{
        setCostfactor(data);
      });
     
    }, [])

  return (
    <>
        <div className="app__cost-head">
            <h1>Cost Factor</h1>
            <div className="app__cost-line"/>
        </div>

        <div className='isntrr'>
        <p>Hover or click on images  👇</p>
        </div>

        <div className='app__cost-container'>
            {costfactor.map((item, index) =>(
                <div className='one' key={index}>
                    <img src={urlFor(item.imgUrl)} alt={item.description} />
                    <p>{item.description}</p>
                </div>
            ))}

        </div>
        <div className='app__content-below'>
            <p>Based on the above factors, we are able to calculate how much it would cost to plant a single tree or crop inconcideration to labor, environment and other climatic factors The cost for maintenance of a single tree is about $20 per year</p>
        </div>
        <div className="app__cost-btn">
            <Link to="/donate"><button>Donate</button></Link>
        </div>
    </>
  )
}


export default MotionWrap(Cost, 'app__testimonial',
  'cost',
);
