import React, {useEffect, useState} from 'react';
import {urlFor, client} from '../../client'

import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "normalize.css/normalize.css";
import "./slider-animations.css";

import './styles.scss'

const Slide = () => {
  const [homeslide, setHomeslide] = useState([]);

  useEffect(() => {
    const query = '*[_type == "homeslide"]';

    client.fetch(query).then((data) => {
      setHomeslide(data);
    });
  }, []);    

  return (
    <>
        <div>
      <Slider 
        className="slider-wrapper" 
        id="home"
        autoplay = "3000"
        touchDisabled = "True"
        >
      {homeslide.map((about, index) => (
        <div
          key={index.title}
          className="slider-content"
         style={{ background: `url('${(urlFor(about.imgUrl))}') no-repeat center center` }}
        >
          <div className="inner">
            <h1>{about.title}</h1>
            <p>{about.description}</p>
            <button><a href={about.buttonLink}>{about.button}</a></button>
          </div>
          <section>
            <img src={about.userProfile} alt={about.user} />
            <span>
              Posted by <strong>{about.user}</strong>
            </span>
          </section>
        </div>
      ))}
    </Slider>
  </div>
    </>
  )
}

export default Slide