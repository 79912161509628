import React, { useState, useEffect } from 'react';
import { MotionWrap } from '../../wrapper';
import { client, urlFor } from '../../client';

import './Inspiration.scss'



const Inspiration = () => {

  const [inspiration, setInspiration] = useState([]);

  useEffect(() => {
    const query = '*[_type == "inspiration"]';

    client.fetch(query).then((data) =>{
      setInspiration(data);
    });
   
  }, [])
  return (
    <>
        <div className="app__head-inspire" id="inspiration">
            <h1>Our Inspiration</h1>
            <div className='inspire_line'/>
        </div>
        {/* <div className='app__descrtz'>
            <h1>Hover on Images bellow to reveal text 👇</h1> 
        </div>     */}
        <div className="app__content-inspire-wrp">
            {inspiration.map((item, index) =>(
                <div className='content-inspire' key={index}>
                    <div className='headerrr'>
                      <img src={urlFor(item.imgUrl)} alt={item.description} />
                      <h1>{item.title}</h1>
                    </div>
                    <div className='contnt'>
                      <p>{item.description}</p>
                    </div>
                    <ul className="profile-social-links">
                      <li>
                        <a href="https://twitter.com/treasure_trees" target="_blank" rel="noreferrer noopener">
                          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/social-twitter.svg" />
                        </a>
                      </li>
                    </ul>
                </div>
            ))}
        </div>
    </>
  )
}


export default MotionWrap(Inspiration, 'app__testimonial',
  'socialbenefits',
);
