import React from 'react'

import { Navbar } from '../../../components'
import trees from '../../../assets/forestry.jpg'
import climate from '../../../assets/climate.jpg'
import model2 from '../../../assets/model2.png'
import agro from '../../../assets/agroforestry2.png'
import agro1 from '../../../assets/agroforestry-benefits.png'
import agro2 from '../../../assets/monoculture.png'
import Forestrygallery from './Forestry-gallery/Forestrygallery'
import Footer from '../../../container/Footer/Footer'




import './Forestry.scss'

const Forestry = () => {

  return (
    <>
        <Navbar/>
        <div className="app__forestry-bg">
          {/* <img src={trees} alt="" /> */}
        </div>
        <div className='app__forestry-head'>
          <h1>Forestry and Agroforestry</h1>
        </div> 

        {/* <div className="app__forestry-fixedbg">
          <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. 
            Repudiandae exercitationem a sunt labore ipsum rem mollitia nam explicabo excepturi,
            hic eaque, eum, ab ipsa cum voluptatibus placeat quia nemo temporibus.</p>
        </div>        */}

        <div className="app__forestry-conteiner">
          <h1>FORESTS, AGRICULTURE & CLIMATE CHANGE</h1>
          <div className='forestry-wrap'>
            <div className="forestry-image">
              <img src={climate} alt="" />
            </div>
            <div className="forestry-par">
              <p>Halting deforestation is a global challenge largely due to unsustainable 
                agricultural practices that degrade natural ecosystems.
               Close to 20% of all 
              carbon emissions result from deforestation and forest degradation.
              Climate change will increasingly affect agricultural conditions and there is an urgent need 
              to make farming practices evolve to adapt to climate change. In 2007, experts at the FAO 
              (Food and Agriculture Organization of the United Nations) stated that <strong>agro-ecology</strong> was the most 
              reliable way to guarantee food security in our future. They based their findings on tangible data, collected in developing countries where industrial agronomy has already showed signs of reaching its limits with: 
              catastrophic erosion, increased climatic instability, desertification.
              </p>
            </div>
          </div>
          <div className='forestry-intergration'>
            <h1>AGROFORESTRY: THE ART OF INTEGRATION</h1>
            <p>Agroforestry is the smart integration of trees into farming systems. 
              Unlike full-sun fields, vulnerable and contributing to ecosystems degradation, 
              agrofrestry is a way to preserve productive ecosystems and adapt to climate change. 
              The trees provide multiple services for improved quality and long-term sustainability of the production.
            </p>
            <p>Different agroforestry models with trees integrated in the perimeter of fields, intercropped, 
              or at landscape level will deliver a mix a various ecosystem services for the farmers.
            </p>
            <div className='intergration-img'>
              <img src={model2} alt="" />
            </div>
            <div className="benefit">
              <h1>MULTIPLE BENEFITS FROM AGROFORESTRY</h1>
            </div>
            <p className='benefits-p'>FROM INTENSIVE MONOCULTURE...</p>
            <img src={agro2} alt="" />
            <p className='benefits-p'>... TO AGROFORESTRY INSIDE FARMS...</p>
            <img src={agro} alt="" />
            <p className='benefits-p'>...AND ECOSYSTEM BALANCE</p>
            <img src={agro1} alt="" />
          </div>
        </div>
        <Forestrygallery/>
        <Footer/>
    </>
  )
}

export default Forestry