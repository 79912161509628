import React from 'react'
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import ScrollToTop from './pages/ScrollToTop/ScrollToTop';
import { ScrollTop } from './components';
import './index.css';




import App from './App';

ReactDOM.render(
        <BrowserRouter>
            <ScrollToTop/>
            <ScrollTop/>
            <App/>
        </BrowserRouter>,
    document.getElementById('root')
);

 