import React from 'react';
import Grid from '@material-ui/core/Grid';

import Product from './Product/Product';
import useStyles from './styles';
import './Products.scss' 

const Products = ({ products, onAddToCart }) => {
  // const classes = useStyles();

  if (!products.length) return <p>Loading...</p>;

  return (
    // <main className={classes.content}>
    //   <div className={classes.toolbar} />
      // <Grid container justify="center" spacing={4}>
      //   {products.map((product) => (
      //     <Grid key={product.id} item xs={12} sm={6}>
      //       <Product product={product} onAddToCart={onAddToCart} />
      //     </Grid>
      //   ))}
      // </Grid>
    // </main>

    <>
    <div className="app__products-detailsss"> 
      <div className="app__details">
        <p>Give a gift that can last a lifetime</p>
        
        <div className="app__monthlybthn">
          <button className="one">Monthly</button>
          <button  className="two">One-time</button>
        </div>

        <h3 className="amounttt">Donation amount (USD)</h3>
      </div>
      <div className='app__choose-amount'>
      {products.map((product) => (
            <div key={product.id} item xs={12} sm={6} className="one">
              <Product product={product} onAddToCart={onAddToCart} />
            </div>
          ))}
      </div>

    </div>
    </>
  );
};

export default Products;

