import React, { useState, useEffect } from 'react';
import { urlFor, client } from '../../../../client';


import { Footer } from '../../../../container'
import { Navbar } from '../../../../components'
import Work from '../../../../container/Gallery/Work'



import './HistoryTime.scss'

const HistoryTime = () => {



  const [projecthistory, setProjecthistory] = useState([]);

  useEffect(() => {
    const query = '*[_type == "projecthistory"]';

    client.fetch(query).then((data) => {
      setProjecthistory(data);
    });
  }, []);
  const [projecthistoryheader, setProjecthistoryheader] = useState([]);

  useEffect(() => {
    const query = '*[_type == "projecthistoryheader"]';

    client.fetch(query).then((data) => {
      setProjecthistoryheader(data);
    });
  }, []);

  
  return (
    <>
        <Navbar/>

        {/* <Swiper/> */}


        <div className='app__container-linereer'/>

        {projecthistoryheader.map((item,index) =>(
          
        <div className="app__img-bghistrwe"
        key={index}
          style={{ background: `url('${(urlFor(item.imgUrl))}') no-repeat center center`, width:"100%", backgroundSize:"cover" ,  backgroundAttachment:"fixed"}}>
       
          <h1>{item.title}</h1>
          <p>{item.description}</p>
        </div>
        ))}




          {/* Time line data */}

          <div className="app__history-timelinenew" id="timeline">

            {projecthistory.map((item, index) =>(

                <div className="tl-item" key={index}>
                  
                  <div 
                    className="tl-bg" 
                    style={{ background: `url('${((urlFor(item.imgUrl)))}') no-repeat center center`, width:"100%", backgroundSize:"cover" }}>
                    // {/* style={{ backgroundImage: `url(${nature})` }}> */}
                  </div>
                  
                  <div className="tl-year">
                    <p className="">{item.date}</p>
                  </div>

                  <div className="tl-content">
                    <h1>{item.title}</h1>
                    <p>{item.description}</p>
                  </div>

                </div>      
            ))}

          </div>



        

        <Work/>
        <Footer/>
    </>
  )
}

export default HistoryTime