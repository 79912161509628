import React from 'react'
import { MapContainer,Marker,Popup,TileLayer} from 'react-leaflet'
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import L from 'leaflet'

// import tree from "../../../assets/tree-icon.png"


import 'leaflet/dist/leaflet.css'

import './Map.scss'

function getIcon(icon_size){
  return L.icon({
      iconUrl: require("../../../assets/locate-icon.png"),
      iconSize: [icon_size]
    })
  }

const Map = () => {

  const position = [-3.2196104154596124, 40.11588700044775]
  const iconPositions = [
    { "name": "Malindi","position":[-3.2196104154596124, 40.11588700044775], "mark": "Come visit us here"},
  ]



  return (
    <>
    <div className="app__get-here">
      <h1>How to get here</h1>
      <div className='app__howed'/>
    </div>
    <div className='app__map-container'>
      <MapContainer
        center={position} 
        zoom={13}
        className="map-wrap"
      >
        <TileLayer
          // url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

          url= "https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=3K1Jp5leurjC6Gaj4ze9"
          attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        />
        {iconPositions.map((locate,index) =>(

        <Marker position={locate.position} icon={getIcon(23)} key={index} >
          <Popup>{locate.mark}</Popup>
        </Marker>

        ))}

      </MapContainer>
    </div>
    </>
  )
}

export default Map