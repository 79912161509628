import React from 'react';
import { Navbar, ScrollTop} from '../../components';
import { FindUs, Footer, Video, OurPrograms, Portfolio,} from '../../container';
import { motion } from 'framer-motion';
import Contact from '../../container/FindUs/Contact';
import Vision from '../AboutUs/Vision';
import Gallery from '../AboutUs/Grid/AboutGallery';
import Achieve from '../AboutUs/Achieve/Achieve';
import Progress from '../../pages/AboutUs/Progress/Progress'
import Slide from '../../components/New/Slide';
import MissionVision from '../AboutUs/Bg/MissionVision';
import Letter from '../../container/FindUs/News/Letter';
import OurProgress from '../WhereWeAre/OurProgress';
import NewAbout from '../NewAboutUS/NewAbout';
import NewHistory from '../NewHistory/NewHistory';
// import NewHero from '../NewHero/NewHero';
import NewProgress from '../NewProgress/NewProgress'
import Inspiration from '../Inspiration/Inspiration';
import Economic from '../Economic/Economic';
import SoicalBenefit from "../SoicalBenefit/SocialBenefit"
import Cost from '../CostFactor/Cost';
import Collaboration from '../Collaboration/Collaboration';
import AboutNew from '../AboutNew/AboutNew';
import Testimonials from '../../container/Testimonials/Testimonials'
const Home = () => {
  return (
    <div>
      <Navbar/>
      <Slide/>
      {/* <NewHero/> */}
      {/* <HeroHome/> */}
      <NewAbout/>
      <NewHistory/>
      <AboutNew/>
    
      {/* <Gallery/> */}
      {/* <MissionVision/> */}
      {/* <Testing/> */}
      {/* <Achieve/> */}
      {/* <Vision/> */}
      {/* <Testimonials/> */}
      {/* <Work/> */}
      {/* <Video/> */}
      {/* <Progress/> */}
      <NewProgress/>
      {/* <Card/> */}
      <Inspiration/>
      {/* <OurProgress/> */}
      <Economic/>
      <SoicalBenefit/>
      {/* <Cost/> */}
      <Collaboration/>
      {/* <Contact/> */}
      <ScrollTop/>
      <Letter/>
      <Footer/>
    </div>
    
  )
}

export default Home