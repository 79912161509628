import React, { useState, useEffect } from 'react';
// import {Home, AboutUs, Gallery, Error} from './pages'
import {OurTeam, Donate,Contact, OurWork,Gallery,Home,Camp, Error,} from './pages';
// import AboutUs from './pages/AboutUs/AboutUs';
import './App.scss'
import Regenerative from './pages/OurWork/Activity/Regenerative/Regenerative';
import Forestry from './pages/OurWork/Forestry/Forestry';
import Trees from './pages/OurWork/Forestry/TreesCrops/Trees';
import Miles from './pages/AboutUs/Progress/Miles/Miles';
import BsModel from './pages/AboutUs/Bg/BsModel/BsModel';
import Team from './pages/AboutUs/TheTeam/OurTeam/Team';
import ProgressPage from './pages/WhereWeAre/ProgressPage/ProgressPage';
import TreesDetails from './pages/OurWork/Forestry/TreesCrops/TheTrees/TreesDetails/TreesDetails';
import CropsDetails from './pages/OurWork/Forestry/TreesCrops/TheCrops/CropsDetails/CropsDetails';
import Donatenow from './pages/Donate/Donatenow/Donatenow';
import Community from './pages/OurWork/Community/Community';
import Products from './components/Commerce/TheProducts/App'
// Donation Pages
import Cart from './components/Commerce/Thecart/Thecart'
import {Routes, Route } from 'react-router-dom';
import TheCheckout from './components/Commerce/TheCheckout/TheCheckout'
import HistoryTime from './pages/AboutUs/ProjectHistory/HistoryTime/HistoryTime';
// import ProjectHistory from './pages/AboutUs/ProjectHistory/ProjectHistory';
const App = () => {

  return (
    <div>
      <Routes> 

        {/* Donation Pages */}

        <Route path='/products' element={<Products/>}/>
        <Route path='/cart' element={<Cart/>}/>
        <Route path='/checkout' element={<TheCheckout/>}/>
          

        <Route path="/" element={<Home/>} />

        {/* <Route path="/aboutus" element={<AboutUs/>}/> */}
        {/* History links */}
        {/* <Route path = "/ourhistory/projecthistory" element={<ProjectHistory/>}/> */}
        <Route path = "/ourhistory/projecthistory/history-time-line" element={<HistoryTime/>}/>

        {/* Achievements */}
        {/* <Route path='/aboutus/achievement' element={<Miles/>}/> */}

        {/* Business Model Mission & Vision */}
        {/* <Route path='/aboutus/the-business-model' element={<BsModel/>}/> */}



        {/* Our Team */}
        {/* <Route path="/ourteam" element={<OurTeam/>}/> */}
        <Route path="/aboutus/meet-the-team" element={<Team/>}/>


        {/* Donation Routes */}
        <Route path="/donate" element={<Donate/>}/>
        <Route path="/donate-to-treasure-trees" element={<Products/>}/>

        {/* Our Work Links/Paths */}

        <Route path="/ourwork/trees-and-plants" element={<OurWork/>}/>
        <Route path="/ourwork/regenerative-agriculture" element={<Regenerative/>} />
        <Route path="/ourwork/agroforestry-and-reforestation" element={<Forestry/>} />
        {/* <Route path="/ourwork/trees-and-plants" element={<Trees/>} /> */}
        {/* <Route path="/ourwork/trees-and-plants/the-trees-indiginous-spcies/" element={<TreesDetails/>} /> */}
        {/* <Route path="/ourwork/trees-and-plants/the-trees-indiginous-species/" element={<CropsDetails/>} /> */}
        
        {/* Our work Community Outreach */}
        
        <Route path="/ourwork/community-outreach-programs/" element={<Community/>} />


        {/* WHere we are Today Page */}

        {/* <Route path="/aboutus/where-we-are-today" element={<ProgressPage/>} /> */}


        {/* <Route path="/permaculture" element={<Permaculture/>}/> */}
        {/* <Route path="/agroforestry" element={<AgroForestry/>}/> */}
        <Route path="/camp" element={<Camp/>}/>
        <Route path="/treasure-trees-memeories" element={<Gallery/>}/>
        <Route path="*" element={<Error/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
    </div>
  )
};

export default App