import React, {useState} from 'react';
import styled from 'styled-components';
import {FaChevronUp} from 'react-icons/fa';

const ScrollTop = () => {
    const [visible, setVisible] = useState(false);
    window.addEventListener("scroll", ()=>{
        window.pageYOffset> 100 ? setVisible(true): setVisible(false)
    })
  return (
    <Div>
        <a href="#" className={`${visible ? "block" : "none"}`}>
            <FaChevronUp/>
        </a>
    </Div>
  )
}


const Div = styled.div`
    max-width: 100vw;
    .none {
        opacity: 0;
        visibility: hidden;
    }
    a{
        position: fixed;
        bottom: 40px;
        right: 40px;
        background: var(--color-darkblue);
        padding: 1rem;
        border-radius: 100%;
        display: flex;
        justify-content:center;
        align-items: center;
        cursor: pointer;
        z-index: 1;
        transition: 0.4s ease-in-out;
        svg{
            color: #fff;
            font-size: 1.3rem;
            
        }
        svg:hover{
            color: var(--color-darkblue);
        }

    }
    a:hover{
        boder: 1px solid var(--color-blue);
        background: var(--color-blue);
    }
`

export default ScrollTop