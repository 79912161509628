import React, { useState, useEffect } from 'react';
import { client, urlFor } from '../../../../client';
import {motion} from 'framer-motion';
import { Navbar } from '../../../../components'
import Footer from '../../../../container/Footer/Footer'



import './Team.scss'

const Team = () => {
  
  const [teamheader, setTeamheader] = useState([]);

  useEffect(() => {
    const query = '*[_type == "teamheader"]';

    client.fetch(query).then((data) => {
      setTeamheader(data);
    });
  }, []);


  const [theteam, setTheteam] = useState([]);

  useEffect(() => {
    const query = '*[_type == "theteam"]';

    client.fetch(query).then((data) =>{
      setTheteam(data);
    });
   
  }, [])

  const [theteamsocials, setTheteamsocials] = useState([]);

  useEffect(() => {
    const query = '*[_type == "theteamsocials"]';

    client.fetch(query).then((data) =>{
      setTheteamsocials(data);
    });
   
  }, [])



  const [theteamquote, setTheteamquote] = useState([]);

  useEffect(() => {
    const query = '*[_type == "theteamquote"]';

    client.fetch(query).then((data) =>{
      setTheteamquote(data);
    });
   
  }, [])

  return (
    <>
        <Navbar/>

        <div className='app__container-linereer'/>


        {teamheader.map((item,index) =>(
          <div className="app__img-bghistrwe"
            key={index}
            style={{ background: `url('${(urlFor(item.imgUrl))}') no-repeat center center`, width:"100%", backgroundSize:"cover" , backgroundAttachment:"fixed"}}>  
            <h1>{item.title}</h1>
            <p>{item.description}</p>
          </div>
        ))}
       
        <div className='app__theteam-intro' id='theteam'>
            <h1>The Team</h1>
            <div className='linernrn'/>
        </div>
        <div className='app__theteam-containus'>


            <div className="app__theteam-profiles">
                {theteam.map((item,index) =>(
                    <div key={index}>
                       <div className='app__theteam-cards'>
                        <motion.img 
                          whileInView={{opacity: 1}}
                          whileHover= {{scale: 0.99}}
                          transition = {{duration: .5, type: "tween"}}
                          src={urlFor(item.profileImg)} 
                          alt={item.username} 
                          className="head-imgme"
                        />
                        <div className="app__conetint">
                          
                        <h1 className='theteam-headtext1'>{item.username}</h1>
                        <h2 className='theteam-headtext2'>- {item.position} -</h2>
                        <h3 className="theteam-headtext3">{item.description}</h3>
                        
                     

                        <div className="aboutcustom-galleryme">
                          {theteamsocials.map((data, index) =>(
                          <div className="account-image" key={index}>
                            <a href={data.instalink} target="_blank" rel="noopener noreferrer">
                              <motion.img 
                              src={urlFor(data.instagramPng)} 
                              alt={data.instalink}
                              whileInView={{opacity: 1}}
                              whileHover= {{scale: 1.2}}
                              transition = {{duration: .5, type: "tween"}} 
                              className="imger"
                            />
                              
                            </a>
                            <a href={data.twitterlink} target="_blank" rel="noopener noreferrer">
                            <motion.img 
                            src={urlFor(data.TwitterPng)} alt=""
                            whileInView={{opacity: 1}}
                            whileHover= {{scale: 1.2}}
                            transition = {{duration: .5, type: "tween"}} 
                            className="imger"
                            />
                            </a>
                            <a href={data.linkedinlink} target="_blank" rel="noopener noreferrer">
                            <motion.img 
                            src={urlFor(data.linkedinPng)} alt=""
                            whileInView={{opacity: 1}}
                            whileHover= {{scale: 1.2}}
                            transition = {{duration: .5, type: "tween"}} 
                            className="imger"
                            /></a>
                          </div>
                          ))}
                        </div>

                        </div>

                    
                       </div>
                    </div>
                ))}
            </div>


            {theteamquote.map((item, index) =>(

              <div className='ceo-quote' key={index}>
                  <p>"" {item.quote} ""</p>
                  <div className='img-content'>
                      <div className='hero-img'>
                          <a href="">
                          <motion.img 
                          src={urlFor(item.imgUrl)} alt="boy"
                          whileInView={{opacity: 1}}
                          whileHover= {{scale: 1.04}}
                          transition = {{duration: .5, type: "tween"}} 
                          />
                          </a>
                        </div>
                        <div className="text-containme">
                          <h2>{item.username}</h2>
                          <h3>- {item.position} -</h3>
                        </div>
                  </div>
              </div>
            ))}

        </div>

        <Footer/>
    </>
  )
}

export default Team