import React, {useState, useEffect} from 'react'

import {BiChevronRightCircle, BiChevronLeftCircle} from 'react-icons/bi'

import {MdOutlineClose} from 'react-icons/md'
import { urlFor, client } from '../../../client';


import './TheGallery.scss'


const TheGallery = () => {
        
//   const galleryImages = [
// ]

      
  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = (index) => {
    setSlideNumber(index)
    setOpenModal(true)
  }

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0 
    ? setSlideNumber( gallery.length -1 ) 
    : setSlideNumber( slideNumber - 1 )
  }

  // Next Image  
  const nextSlide = () => {
    slideNumber + 1 === gallery.length 
    ? setSlideNumber(0) 
    : setSlideNumber(slideNumber + 1)
  }

  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    const query = '*[_type == "gallery"]';

    client.fetch(query).then((data) => {
      setGallery(data);
    });
  }, []);

  
  return (
    <>

<div>

{openModal && 
  <div className='app__photogallery-sliderwrap'>
    <MdOutlineClose className='btnClose' onClick={handleCloseModal}  />
    <BiChevronLeftCircle  className='btnPrev' onClick={prevSlide} />
    <BiChevronRightCircle className='btnNext' onClick={nextSlide} />
    <div className='app__photogallery-fullscreenImage'>
      <a>
        <img 
        src={urlFor(gallery[slideNumber].imgUrl)} 
        alt="" 
        />
      </a>
    </div>
  </div>
}


<div className='app__photogallery-wrapper'>
  {
    gallery && gallery.map((slide, index) => {
      return(
        <div 
          className='single' 
          key={index}
          onClick={ () => handleOpenModal(index) }
        >
          <img src={urlFor(slide.imgUrl)} alt={slide.title} />
        </div>
      )
    })
  }
</div>

</div>
    
    </>
  )
}

export default TheGallery