import React, {useState, useEffect} from 'react'
import { urlFor, client } from '../../client'

import { motion } from 'framer-motion';
import { MotionWrap } from '../../wrapper';

import './NewAbout.scss'
const NewAbout = () => {

  const [aboutgallery, setAboutgallery] = useState([]);

  useEffect(() => {

    const query = '*[_type == "aboutgallery"]';

    client.fetch(query).then((data) =>{
      setAboutgallery(data);
    });
   
  }, []);
  

  const [aboutusgallery, setAboutusgallery] = useState([]);

  useEffect(() => {

    const query = '*[_type == "aboutusgallery"]';

    client.fetch(query).then((data) =>{
      setAboutusgallery(data);
    });
   
  }, []);
  
  const [aboutcontent, setAboutcontent] = useState([]);

  useEffect(() => {

    const query = '*[_type == "aboutcontent"]';

    client.fetch(query).then((data) =>{
      setAboutcontent(data);
    });
   
  }, []);

  return (
    <>
        <div className="app___newabout-bg"/>

        <div className="app__newabout-container" id="about">
          <div className='app__newer-head'>
            <h1>About Us</h1>
            <div className='app__lenrern'/>
          </div>

          {aboutcontent.map((item, index) =>(
    
          <div className='content' key={index}>
            <h2>{item.description}</h2>
          </div> 
          ))}
        </div>

        <div className="app__newabout-img">
          <div className='thw-imgages'>
            {aboutusgallery.map((myitem, index) =>(
              <div className='img' key={index}>
                <img
                  src={urlFor(myitem.imgUrl)}
                 alt="img" />
              </div>
            ))}
          </div>

        </div> 

        <div className='app__newbout-headme'>
          <h1>Our Values</h1>
          <div className='app__newavil'/>
        </div>

          <div className='app__aboutus-org'>
          {aboutgallery.map((item, index) =>(
            <div className='content' key={index}>
              <motion.img 
              src={urlFor(item.imgUrl)}
              alt={item.title}
              whileInView={{opacity: 1}}
              whileHover= {{scale: 1.08}} 
              />
              <h1>{item.title}</h1>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
    </>
  )
}



export default MotionWrap(NewAbout, 'app__testimonial',
  'progress',
);
