import React, {useEffect, useState} from 'react';
import {urlFor, client} from '../../../../client'

import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "normalize.css/normalize.css";
import "./slider-animations.css";

import './Comm.scss'

const Slide = () => {
  const [commheader, setCommheader] = useState([]);

  useEffect(() => {
    const query = '*[_type == "commheader"]';

    client.fetch(query).then((data) => {
      setCommheader(data);
    });
  }, []);    

  return (
    <>
        <div>
      <Slider 
        className="slider-wrapper-comm" 
        id="home"
        autoplay = "3000"
        touchDisabled = "True"
        >
      {commheader.map((about, index) => (
        <div
          key={index.title}
          className="slider-content"
         style={{ background: `url('${(urlFor(about.imgUrl))}') no-repeat center center`,backgroundSize:"cover", backgroundPosition:"center"  }}
        >
          <div className="inner">
            <h1>{about.title}</h1>
            <p>{about.description}</p>
            <button className="app__coombtin"><a href={about.buttonLink}>{about.button}</a></button>
          </div>
        </div>
      ))}
    </Slider>
  </div>
    </>
  )
}

export default Slide