import React from 'react'
import TheTrees from './TheTrees/TheTrees'
import EndTrees from './EndTrees/EndTrees'
import TheCrops from './TheCrops/TheCrops'


import './Trees.scss'

const Trees = () => {
  return (
    <>
        {/* <TheTrees/> */}
        <TheCrops/>
        <EndTrees/>
    </>
  )
}

export default Trees