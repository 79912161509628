import logo from '../assets/logo.png';
import logo2 from '../assets/logo2.png';
import logo3 from '../assets/logo3.png';
import forestry1 from '../assets/forestry1.jpg';
import lovetree1 from '../assets/lovetree1.jpg';
import nustrees from '../assets/nustrees.jpg';
import pinetrees from '../assets/pinetrees.jpg';
import plant from '../assets/plant.jpg';
import trees2 from '../assets/trees2.jpg';
import trees3 from '../assets/trees3.jpg';
import trees4 from '../assets/trees4.jpg';
import trees5 from '../assets/lovetree1.jpg';
import trees6 from '../assets/trees6.jpg';
import trees7 from '../assets/trees7.jpg';
import trees8 from '../assets/trees8.jpg';
import spoon from '../assets/spoon.png';
import welcome from '../assets/welcome.png';
import bob from '../assets/bob.jpg';
import quote from '../assets/quote.png';
import loadmore from '../assets/new/loadmore.png';
import portfolio3 from '../assets/new/portfolio3.jpg';
import portfolio4 from '../assets/new/portfolio4.jpg';
import portfolio5 from '../assets/new/portfolio5.jpg';
import portfolio6 from '../assets/new/portfolio6.jpg';
import portfolio7 from '../assets/new/portfolio7.jpg';
import portfolio8 from '../assets/new/portfolio8.jpg';
import portfolio9 from '../assets/new/portfolio9.jpg';
import portfolio10 from '../assets/new/portfolio10.jpg';
import logo5 from '../assets/logo5.png';
import logo4 from '../assets/logo4.png';
import moringa from '../assets/moringa.jpg';
import moringa2 from '../assets/moringa2.jpg';
import moringa3 from '../assets/moringa3.jpg';
import whistlingpine from '../assets/whistlingpine.jpg';
import pawpaw2 from '../assets/pawpaw2.jpg';
import pineapple from '../assets/pineapple.jpg';
import mango2 from '../assets/mango2.jpg';
import greengrams from '../assets/greengrams.jpg';

export default{ 
    mango2,
    pawpaw2,
    pineapple,
    greengrams,
    moringa,
    moringa2,
    moringa3,
    whistlingpine,
    logo5,
    logo4,
    loadmore,
    portfolio3,
    portfolio4,
    portfolio5,
    portfolio6,
    portfolio7,
    portfolio8,
    portfolio9,
    portfolio10,
    quote,
    bob,
    welcome,
    spoon,
    logo,
    logo2,
    logo3,
    forestry1,
    lovetree1,
    nustrees,
    pinetrees,
    plant,
    trees2,
    trees3,
    trees4,
    trees5,
    trees6,
    trees7,
    trees8,
};

